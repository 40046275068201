// import React, { useState } from "react";
// import "../sidebar/SideBar.css";

// const DateAndSort = ({ handleSortChange, currentSortOrders,dateField,notes }) => {
//   const [sortOrderNotes, setSortOrderNotes] = useState(
//     currentSortOrders?.notes || "asc"
//   );
//   const [activeSort, setActiveSort] = useState(dateField); // Initialize with 'notes' active
//   const [sortOrderCreatedAt, setSortOrderCreatedAt] = useState(
//     currentSortOrders?.created_at || "asc"
//   );
//   const [lastTaskEdited, setLastTaskEdited] = useState(
//     currentSortOrders?.post_comments_Updated_at || "asc"
//   );

//   const [dueDate, setDueDate] = useState(currentSortOrders?.commission_due_date || "asc")

//   const handleSortToggle = (sortBy, sortType) => {
//     let newSortOrder;

//     // Toggle the active sort type
//     setActiveSort(sortType);

//     // Determine the new sort order based on the clicked column
//     if (sortBy === "notes") {
//       newSortOrder = sortOrderNotes === "asc" ? "desc" : "asc";
//       setSortOrderNotes(newSortOrder);
//     } else if (sortBy === "created_at") {
//       newSortOrder = sortOrderCreatedAt === "asc" ? "desc" : "asc";
//       setSortOrderCreatedAt(newSortOrder);
//     } else if (sortBy === notes) {
//       newSortOrder = lastTaskEdited === "asc" ? "desc" : "asc";
//       setLastTaskEdited(newSortOrder);
//     } else if (sortBy === dateField) {
//       newSortOrder = dueDate === "asc" ? "desc" : "asc";
//       setDueDate(newSortOrder);
//     }

//     // Trigger sort change with the updated parameters
//     handleSortChange(sortBy, newSortOrder);
//   };

  

//   return (
//     <div className="sort-hide">
//       <div
//         className="accordion listoption mt-3"
//         id="accordionPanelsStayOpenExample"
//       >
//         <div className="accordion-item custom-accordion">
//           <h2 className="accordion-header accordian-sort custom-acrdn-btn">
//             <button
//               className="accordion-button accordian-outer-button custom-acrdn-btn border-bottom custom-navs p-0"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#panelsStayOpen-collapseOne"
//               aria-expanded="true"
//               aria-controls="panelsStayOpen-collapseOne"
//             >
//               List Options
//             </button>
//           </h2>
//           <div
//             id="panelsStayOpen-collapseOne"
//             className="accordion-collapse collapse show"
//           >
//             <div className="accordion-body custom-body">
//               <div className="accordion-item custom-accordion">
//                 <ul className="list-group list-inline border-bottom">
//                   <li>
//                     <div className="accordion-item custom-accordion">
//                       <h2 className="accordion-header inner-accordian accordian-sort custom-acrdn-btn sortby">
//                         <button
//                           className="accordion-button custom-acrdn-btn collapsed p-0"
//                           type="button"
//                           data-bs-toggle="collapse"
//                           data-bs-target="#panelsStayOpen-collapseTwo"
//                           aria-expanded="false"
//                           aria-controls="panelsStayOpen-collapseTwo"
//                         >
//                           <a className="sub-btn d-flex" href="#">
//                             <img
//                               src="https://yesdone.com/build/assets/bararrow-4957c89c.png"
//                               className="doneimg"
//                               width="20px"
//                               alt=""
//                             />
//                             <span className="sort-data">Sort By</span>
//                           </a>
//                         </button>
//                       </h2>
//                       <div
//                         id="panelsStayOpen-collapseTwo"
//                         className="accordion-collapse collapse show"
//                       >
//                         <div className="accordion-body custom-body ps-5">
//                           <ul className="list-inline">
//                           <li className="pt-2 ps-5 custom-text pt-3 fs-6 ps-5 cursor-point">
//                               {activeSort === "commission_due_date" && (
//                                 <img
//                                   src="https://yesdone.com/build/assets/greyright-fa64ca6c.png"
//                                   className="rightgrey me-2 active-sort"
//                                   alt="sort images"
//                                 />
//                               )}
//                               <span
//                               className="sort-data"
//                                 onClick={() =>
//                                   handleSortToggle("commission_due_date", "commission_due_date")
//                                 }
//                               >
//                                 Due Date
//                               </span>
//                             </li>
//                             <li className="pt-2 ps-5 custom-text pt-3 fs-6 ps-5 cursor-point">
//                               {activeSort === "notes" && (
//                                 <img
//                                   src="https://yesdone.com/build/assets/greyright-fa64ca6c.png"
//                                   className="rightgrey me-2 active-sort"
//                                   alt="sort images"
//                                 />
//                               )}
//                               <span
//                               className="sort-data"
//                                 onClick={() =>
//                                   handleSortToggle("notes", "notes")
//                                 }
//                               >
//                                 Name
//                               </span>
//                             </li>
//                             <li className="custom-text pt-3 fs-6 ps-5 cursor-point">
//                               {activeSort === "created_at" && (
//                                 <img
//                                   src="https://yesdone.com/build/assets/greyright-fa64ca6c.png"
//                                   className="rightgrey me-2 active-sort"
//                                   alt="sort images"
//                                 />
//                               )}
//                               <span 
//                               className="sort-data"
//                                 onClick={() =>
//                                   handleSortToggle("created_at", "created_at")
//                                 }
//                               >
//                                 Posting Date{" "}
//                               </span>
//                             </li>
//                             <li className="custom-text pt-3 fs-6 ps-5 cursor-point">
//                               {activeSort === "post_comments_updated_at" && (
//                                 <img
//                                   src="https://yesdone.com/build/assets/greyright-fa64ca6c.png"
//                                   className="rightgrey me-2 active-sort"
//                                   alt="sort images"
//                                 />
//                               )}
//                               <span
//                               className="sort-data"
//                                 onClick={() =>
//                                   handleSortToggle(
//                                     "post_comments_updated_at",
//                                     "post_comments_updated_at"
//                                   )
//                                 }
//                               >
//                                 Last task edited{" "}
//                               </span>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DateAndSort;

import React, { useState, useEffect, createContext } from "react";
import "../sidebar/SideBar.css";

const DateAndSort = ({ handleSortChange, currentSortOrders, dateField, alphabetical, createdat, Updatedat }) => {
  const [sortOrderNotes, setSortOrderNotes] = useState("asc");
  const [sortOrderCreatedAt, setSortOrderCreatedAt] = useState("asc");
  const [sortOrderLastEdited, setSortOrderLastEdited] = useState("asc");
  const [sortOrderDueDate, setSortOrderDueDate] = useState("asc");
  const [activeSort, setActiveSort] = useState(createdat);

  // Effect to set initial sort orders based on currentSortOrders prop
  useEffect(() => {
    if (currentSortOrders) {
      setSortOrderNotes(currentSortOrders.alphabetical || "asc");
      setSortOrderCreatedAt(currentSortOrders.created_at || "asc");
      setSortOrderLastEdited(currentSortOrders.post_comments_updated_at || "asc");
      setSortOrderDueDate(currentSortOrders.due_date || "asc");
    }
  }, [currentSortOrders]);

  const handleSortToggle = (sortBy, sortType) => {
    let newSortOrder;

    // Toggle the active sort type
    setActiveSort(sortType);

    // Determine the new sort order based on the clicked column
    switch (sortBy) {
      case alphabetical:
        newSortOrder = sortOrderNotes === "asc" ? "desc" : "asc";
        setSortOrderNotes(newSortOrder);
        break;
      case createdat:
        newSortOrder = sortOrderCreatedAt === "asc" ? "desc" : "asc";
        setSortOrderCreatedAt(newSortOrder);
        break;
      case Updatedat:
        newSortOrder = sortOrderLastEdited === "asc" ? "desc" : "asc";
        setSortOrderLastEdited(newSortOrder);
        break;
      case dateField:
        newSortOrder = sortOrderDueDate === "asc" ? "desc" : "asc";
        setSortOrderDueDate(newSortOrder);
        break;
      default:
        newSortOrder = "asc"; // Fallback in case of unexpected sortBy values
    }

    // Trigger sort change with the updated parameters
    handleSortChange(sortBy, newSortOrder);
  };


  return (
    <div className="sort-hide">
      <div
        className="accordion listoption mt-3"
        id="accordionPanelsStayOpenExample"
      >
        <div className="accordion-item custom-accordion">
          <h2 className="accordion-header accordian-sort custom-acrdn-btn">
            <button
              className="accordion-button accordian-outer-button custom-acrdn-btn border-bottom custom-navs p-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              List Options
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show"
          >
            <div className="accordion-body custom-body">
              <div className="accordion-item custom-accordion">
                <ul className="list-group list-inline border-bottom">
                  <li>
                    <div className="accordion-item custom-accordion">
                      <h2 className="accordion-header inner-accordian accordian-sort custom-acrdn-btn sortby">
                        <button
                          className="accordion-button custom-acrdn-btn collapsed p-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          <a className="sub-btn d-flex" href="#">
                            <img
                              src="https://yesdone.com/build/assets/bararrow-4957c89c.png"
                              className="doneimg"
                              width="20px"
                              alt=""
                            />
                            <span className="sort-data">Sort By</span>
                          </a>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                      >
                        <div className="accordion-body custom-body ps-5">
                          <ul className="list-inline">
                            <li className="pt-2 ps-5 custom-text pt-3 fs-6 ps-5 cursor-point">
                              {activeSort === dateField && (
                                <img
                                  src="https://yesdone.com/build/assets/greyright-fa64ca6c.png"
                                  className="rightgrey me-2 active-sort"
                                  alt="sort images"
                                />
                              )}
                              <span
                                className="sort-data"
                                onClick={() =>
                                  handleSortToggle(dateField, dateField)
                                }
                              >
                                Due Date
                              </span>
                            </li>
                            <li className="pt-2 ps-5 custom-text pt-3 fs-6 ps-5 cursor-point">
                              {activeSort === alphabetical && (
                                <img
                                  src="https://yesdone.com/build/assets/greyright-fa64ca6c.png"
                                  className="rightgrey me-2 active-sort"
                                  alt="sort images"
                                />
                              )}
                              <span
                                className="sort-data"
                                onClick={() =>
                                  handleSortToggle(alphabetical, alphabetical)
                                }
                              >
                                Name
                              </span>
                            </li>
                            <li className="custom-text pt-3 fs-6 ps-5 cursor-point">
                              {activeSort === createdat && (
                                <img
                                  src="https://yesdone.com/build/assets/greyright-fa64ca6c.png"
                                  className="rightgrey me-2 active-sort"
                                  alt="sort images"
                                />
                              )}
                              <span
                                className="sort-data"
                                onClick={() =>
                                  handleSortToggle(createdat, createdat)
                                }
                              >
                                Posting Date{" "}
                              </span>
                            </li>
                            <li className="custom-text pt-3 fs-6 ps-5 cursor-point">
                              {activeSort === Updatedat && (
                                <img
                                  src="https://yesdone.com/build/assets/greyright-fa64ca6c.png"
                                  className="rightgrey me-2 active-sort"
                                  alt="sort images"
                                />
                              )}
                              <span
                                className="sort-data"
                                onClick={() =>
                                  handleSortToggle(
                                   Updatedat,
                                   Updatedat
                                  )
                                }
                              >
                                Last task edited{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateAndSort;

