// import "./Popup.css";
// import React, { useState, useRef, useEffect } from "react";

// const Popup = ({ url, isVideo }) => {
//   const videoRef = useRef(null);
//   const [isOpen, setIsOpen] = useState(false);
//   const [mediaUrl, setMediaUrl] = useState("");

//   useEffect(() => {
//     const handleKeyDown = (e) => {
//       if (e.key === "Escape") {
//         closePopup();
//       }
//     };

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, []); // Empty dependency array ensures this effect only runs once

//   const openPopup = (url) => {
//     setMediaUrl(url);
//     setIsOpen(true);
//   };

//   const closePopup = () => {
//     setMediaUrl("");
//     setIsOpen(false);

//     // Stop video playback if a video is currently playing
//     if (videoRef.current) {
//       const iframe = videoRef.current.contentWindow;
//       if (iframe) {
//         iframe.postMessage(
//           '{"event":"command","func":"pauseVideo","args":""}',
//           "*"
//         );
//       }
//     }
//   };

//   const handleOverlayClick = (e) => {
//     // Close the popup when clicking outside of it
//     if (e.target === e.currentTarget) {
//       closePopup();
//     }
//   };

//   return (
//     <div className="ps-2">
//       <span>
//         {isVideo ? (
//           <img
//             src="https://beta.yesdone.com/build/assets/youtube1-bc2b8a44.png"
//             alt=""
//             width="25px"
//             height="19px"
//             onClick={() => openPopup(url)}
//           />
//         ) : (
//           <img
//             src="https://yesdone.com/build/assets/dummyimg-68fadc9e.png"
//             alt=""
//             width="25px"
//             height="19px"
//             onClick={() => openPopup(url)}
//           />
//         )}
//       </span>

//       {isOpen && (
//         <div className="popup-overlay" onClick={handleOverlayClick}>
//           <div className="popup-content">
//             {mediaUrl && (
//               <div className="media-container">
//                 <span className="popup-vi">
//                   {mediaUrl.includes("youtube.com") ||
//                   mediaUrl.includes("youtu.be") ? (
//                     <iframe
//                       ref={videoRef}
//                       title="YouTube Video"
//                       width="400"
//                       height="315"
//                       src={mediaUrl}
//                       allowFullScreen
//                     ></iframe>
//                   ) : mediaUrl.includes(".mp4") ? (
//                     <video
//                       ref={videoRef}
//                       controls
//                       width="450px"
//                       height="450px"
//                       className="object-fit-cover"
//                     >
//                       <source src={mediaUrl} type="video/mp4" />
//                       Your browser does not support the video tag.
//                     </video>
//                   ) : (
//                     <img
//                       src={mediaUrl}
//                       alt="Popup Media"
//                       width="250px"
//                       height="auto"
//                       className="object-fit-cover"
//                     />
//                   )}
//                 </span>
//                 <button className="close-button" onClick={closePopup}>
//                   <i className="fa fa-times"></i> {/* Close icon */}
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Popup;
import "./Popup.css";
import React, { useState, useRef, useEffect } from "react";

const Popup = ({ url, isVideo, text }) => {
  const videoRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        closePopup();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array ensures this effect only runs once

  const openPopup = (url) => {
    setMediaUrl(url);
    setIsOpen(true);
  };

  const closePopup = () => {
    setMediaUrl("");
    setIsOpen(false);

    // Stop video playback if a video is currently playing
    if (videoRef.current) {
      const iframe = videoRef.current.contentWindow;
      if (iframe) {
        iframe.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          "*"
        );
      }
    }
  };

  const handleOverlayClick = (e) => {
    // Close the popup when clicking outside of it
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };

  return (
    <div className="ps-2">
      <span>
        {isVideo ? (
          <img
            src="https://beta.yesdone.com/build/assets/youtube1-bc2b8a44.png"
            alt=""
            width="25px"
            height="19px"
            onClick={() => openPopup(url)}
          />
        ) : (
          <img
            src="https://yesdone.com/build/assets/dummyimg-68fadc9e.png"
            alt=""
            width="25px"
            height="19px"
            onClick={() => openPopup(url)}
          />
        )}
      </span>

      {isOpen && (
  <div className="popup-overlay" onClick={handleOverlayClick}>
    <div className="popup-content">
    {/* <p className="popup-text text-center text-black fs-4">{text}</p> */}
      {mediaUrl && (
        <div className="media-container">
          <span className="popup-vi">
            {mediaUrl.includes("youtube.com") ||
            mediaUrl.includes("youtu.be") ? (
              <iframe
                ref={videoRef}
                title="YouTube Video"
                src={mediaUrl}
                className="responsive-iframe"
                allowFullScreen
              ></iframe>
            ) : mediaUrl.includes(".mp4") ? (
              <video
                ref={videoRef}
                controls
                width="450px"
                height="450px"
                className="object-fit-cover"
              >
                <source src={mediaUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={mediaUrl}
                alt="Popup Media"
                className="object-fit-cover popup-img"
              />
            )}
          </span>
          <button className="close-button" onClick={closePopup}>
            <i className="fa fa-times"></i>
          </button>
        </div>
      )}
    </div>
  </div>
)}

    </div>
  );
};

export default Popup;
