import API_BASE_URL from '../Config.js';

export const fetchPostData = async (id, status, page = 1, sortBy = 'created_at', sortOrder = 'desc',searchQuery) => {
  try {
    const storedToken = localStorage.getItem("token");
    let apiUrl = `${API_BASE_URL}/api/v1/posts/${id}/todos?status=${status}&per_page=20&page=${page}`;

    if (searchQuery && searchQuery.trim().length > 0) {
      apiUrl += `&search=${encodeURIComponent(searchQuery.trim())}`;
    }
    
    // Add sorting parameters to the URL
    apiUrl += `&sort_by[${sortBy}]=${sortOrder}`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${storedToken}`
      }
    });
    
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


// export const fetchPostData = async (
//   selectedItemId,
//   activeTab,
//   pageNo,
//   selectedItemType,
//   sortBy = 'commission_due_date', // Default to sorting by due_date
//   sortOrder = 'desc', // Default to descending order
//   signal
// ) => {
//   try {
//     const storedToken = localStorage.getItem("token");

//     // Check for required parameters
//     if (!selectedItemId || !storedToken) {
//       throw new Error("Missing required parameters");
//     }

//     // Construct the base URL
//     let apiUrl = `${API_BASE_URL}/api/v1/posts/${selectedItemId}/todos?status=${activeTab}&per_page=20&page=${pageNo}`;

//     // Append parameters based on the selected item type
//     if (selectedItemType === "user") {
//       apiUrl += `&user_id=${selectedItemId}&type=to-do`;
//     } else if (selectedItemType === "group") {
//       apiUrl += `&group_id=${selectedItemId}&type=to-do`;
//     }

//     // Append sorting parameters
//     const sortParam = `&sort_by[${sortBy || 'due_date'}]=${sortOrder}`;
//     apiUrl += sortParam;

//     // Set up the headers
//     const headers = {
//       'Authorization': `Bearer ${storedToken}`,
//     };

//     console.log("API URL:", apiUrl); // Log the constructed API URL

//     // Fetch data from the API
//     const response = await fetch(apiUrl, { headers, signal });

//     // Handle unsuccessful responses
//     if (!response.ok) {
//       const errorMessage = await response.text();
//       throw new Error(`Network response was not ok: ${errorMessage}`);
//     }

//     // Return the parsed JSON data
//     return await response.json();
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// };

  

  