import React, { useEffect, useState, useRef, useContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { fetchPostData } from "../../Api/MiniTodo";
import { Tabs, Tab } from "react-bootstrap";
import LoadingImg from "../../images/loading.webp";
import { useNavigate, useSearchParams } from "react-router-dom"; // Import the fetchPostData function
import { genNewSearchParamString } from "../../utils";
import API_BASE_URL from "../../Config";
import { useMiniTodoData } from "../AuthContext/minitodos";
import DateAndSort from "../datesort/DateAndSort";
import { Modal, Button } from "react-bootstrap";
import "./minipost.css";
import MiniDelete from "./MiniDelete";
import MiniDone from "./MiniDone";
import MiniApprove from "./MiniApprove";
import axios from "axios";
import MiniTodo from "./MiniTodo";
import ImagePreview from "../../utils/image-preview";
import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
  getTodoReqParams,
} from "../../utils/index";
import { useTodo } from "../AuthContext/TodoContext";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import StaticBox from "./StaticBox";
import ToastContainer from "../toast/ToastContainer";
import UserContext from "../AuthContext/ChatContext";
const MiniPost = () => {
  // Extract id and status from URL params
  const { miniTodoData, setMiniTodoData, count, setCount } = useMiniTodoData();
  const toastRef = useRef();
  const [todos, setTodos] = useState([]);
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  const { id, postid } = useParams();
  const [data, setData] = useState({
    post: { notes: "" }, // Default value for post
    pending_count: 0, // Default value for pending_count
    done_count: 0, // Default value for done_count
    deleted_count: 0, // Default value for deleted_count
    approve_count: 0,
  });
  const [title1, setTitle1] = useState("");
  const selectedType = localStorage.getItem("selectedType");
  const [isUrgent, setIsUrgent] = useState(0);
  const [checkBoxModal, setCheckBoxModal] = useState(false);
  const [tempUrgentState, setTempUrgentState] = useState(false);
  const selectedItemId1 = localStorage.getItem("selectedItemId");
  const firstId1 = localStorage.getItem("firstId");
  const firstType1 = localStorage.getItem("firstType");
  const { groupName } = useContext(UserContext);
  const userId = localStorage.getItem("user_id");
  const {
    selectedItemId,
    selectedItemName,
    selectedItemType,
    firstTodoName,
    firstId,
    firstType,
  } = useTodo();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [localIsSaved, setLocalIsSaved] = useState(data?.post?.is_saved);
  const activeTab = searchParams.get("status");

  const status = searchParams.get("status");
  const [sortOrder, setSortOrder] = useState("desc"); // Default sorting order
  const [sortBy, setSortBy] = useState("post_comments.created_at");
  const [showCalander, setShowCalander] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const currentDate = new Date(); // current date
  const sevenDays = new Date(currentDate);
  sevenDays.setDate(currentDate.getDate() + 7);
  const [selectedDate, setSelectedDate] = useState(() => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + 7);
    return date;
  });
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = selectedDate.toLocaleDateString("en-US", options);
  const [isIconUp, setIsIconUp] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleIconClick = async () => {
    const sort = !isIconUp ? "asc" : "desc";
    setIsIconUp((prevState) => !prevState);
    try {
      // Call the fetchPostData function

      setLoading(true);
      const data = await fetchPostData(id, status, 1, sort);
      console.log("Fetched data:", data); // Log fetched data to console
      setTodos(data.data.todos);
      setCount({
        todoCount: data.data.pending_count || 0,
        doneCount: data.data.done_count || 0,
        deleteCount: data.data.deleted_count || 0,
        approveCount: data.data.approve_count || 0,
      });
      setMiniTodoData(data.data);
      setData(data?.data);
      console.log(data.data.todos);
      console.log(data?.data?.pending_count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched (whether successfully or with error)
    }
  };

  const handleCheckboxChange = (e) => {
    // Open the modal when the checkbox is clicked
    setTempUrgentState(e.target.checked); // Track the intended checkbox state
    setCheckBoxModal(true); // Show modal
  };

  const handleCheckBoxClick = () => {
    // Confirm action
    const urgentValue = tempUrgentState ? 1 : 0;
    console.log("Value to pass:", urgentValue);
    setIsUrgent(urgentValue); // Update the actual checkbox state
    setCheckBoxModal(false); // Close modal

    // Pass value (1 for checked, 0 for unchecked) to your API or backend

    // Add your API call or logic here
  };
  console.log("isUrgent", isUrgent);

  const handleCloseCheckboxModal = () => {
    // Dismiss modal and revert the checkbox to its previous state
    setCheckBoxModal(false);
  };
  const dateField = "post_comments.due_date";
  const notes = "post_comments.comment";
  const createdat = "post_comments.created_at";
  const Updatedat = "post_comments.updated_at";

  const handleSortChange = async (newSortBy, newSortOrder) => {
    try {
      let sortByParam;
      switch (newSortBy) {
        case createdat:
          sortByParam = "post_comments.created_at";
          break;
        case Updatedat:
          sortByParam = "post_comments.updated_at";
          break;
        case notes:
          sortByParam = "post_comments.comment";
          break;
        case dateField:
          sortByParam = "post_comments.due_date";
          break;
        default:
          sortByParam = "post_comments.due_date";
      }

      setTodos([]);
      setMiniTodoData({ todos: [] });
      setSortBy(sortByParam);
      setSortOrder(newSortOrder);
      setLoading(true);

      const data = await fetchPostData(
        id,
        status,
        1,
        sortByParam,
        newSortOrder
      );
      setTodos(data.data.todos);
      setMiniTodoData({ todos: data.data.todos });
      setCount({
        todoCount: data.data.pending_count || 0,
        doneCount: data.data.done_count || 0,
        deleteCount: data.data.deleted_count || 0,
        approveCount: data.data.approve_count || 0,
      });
      setData(data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowCalander = () => {
    setShowCalander(!showCalander);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getInitialDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date;
  };
  const handleTextChange = (e) => {
    setTitle1(e.target.value);
  };
  const handleFileChange = async (event) => {
    setThumbnail(null);
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image")) {
        const cfile = await compressImage(file);
        setFile(cfile);
      } else {
        setFile(file);
        const thumbnail = await videoThumbnail(file);
        const cfile = await compressImage(thumbnail);
        setThumbnail(cfile);
      }
    }
  };
  const formatDateToLocalISO = (date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().split("T")[0];
  };
  const handleFileDelete = () => {
    setFile(null);
    setThumbnail(null);
  };
  console.log(
    "groupname,selectedname,firstTodoName",
    groupName,
    selectedItemName,
    firstTodoName
  );
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const storedToken = localStorage.getItem("token");
      let bodyData;
      let title = event.target.title.value;
      const urgent = isUrgent
      const formattedSelectedDate = formatDateToLocalISO(selectedDate);
      const currentDate = new Date(); // current date
      const sevenDays = new Date(currentDate);
      sevenDays.setDate(currentDate.getDate() + 7);
      const formattedDate =
        formattedSelectedDate || sevenDays.toISOString().split("T")[0];
      // Check if the user has uploaded an image or video
      if (file) {
        const imgVideoUrl = await uploadFileToS3(file);
        const fileType = file.type.startsWith("image") ? "image" : "video";
        bodyData = {
          post_id: id,
          user_id: userId,
          type_of: `task_${fileType}`, // Set type_of to task_image for images and task_video for videos
          comment: `${imgVideoUrl}|&|${title || ""}`,
          status: "still_working",
          extremely_urgent :urgent,
          due_date: formattedDate,
        };
      } else if (thumbnail) {
        const imgVideoUrl = await uploadFileToS3(thumbnail);
        bodyData = {
          post_id: id,
          user_id: userId,
          type_of: "task_video", // Set type_of to task_video for videos
          comment: `${imgVideoUrl}|&|${title || ""}`,
          status: "still_working",
          extremely_urgent :urgent,
          due_date: formattedDate,
        };
      } else {
        // Default case: text only
        bodyData = {
          post_id: id,
          user_id: userId,
          type_of: "task", // Set type_of to task for text comments
          comment: title,
          status: "still_working",
          extremely_urgent :urgent,
          due_date: formattedDate,
        };
      }

      const response = await fetch(`${API_BASE_URL}/api/v1/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        // Handle success
        console.log("minitodoData", bodyData);

        const newComment = await response.json();
        console.log("Comment added successfully", newComment.data);
        const newData = newComment.data;

        // Create a new array with the added object at the beginning
        const newPosts = [newData, ...miniTodoData.todos];

        // Update todoCount and posts in the state
        setMiniTodoData((prevTodoData) => ({
          ...prevTodoData,

          todos: newPosts,
        }));
        setCount((prevTodoData) => ({
          ...prevTodoData,
          todoCount: prevTodoData.todoCount + 1,
        }));
        // Optionally, you can clear the comment input field here
        // setComment("");
        setShowCalander(false); // Hide the calendar
        setSelectedDate(getInitialDate());
        toastRef.current.showToast("Post Added Successfully!");
        setFile(null);
        setIsUrgent(0);
        setThumbnail(null);
        setTitle1("");

        // Fetch data again after a successful API call
        // await fetchData();
      } else {
        // Handle errors
        console.error("Failed to add comment");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  // State to store data fetched from the API

  // Fetch data from the API when component mounts
  const handleSelect = (key) =>
    navigate(genNewSearchParamString(searchParams, "status", key));
  // Define the fetchData function
  const fetchData = async (
    sortBy = "post_comments.created_at",
    sortOrder = "desc"
  ) => {
    try {
      setLoading(true);
      // Clear the state before fetching new data
      setTodos([]);
      setMiniTodoData({ todos: [] });

      // Fetch fresh data with sorting parameters
      const data = await fetchPostData(id, status, 1, sortBy, sortOrder,searchQuery);

      // Update state with fetched data
      setTodos(data.data.todos);
      setMiniTodoData({ todos: data.data.todos });

      setCount({
        todoCount: data.data.pending_count || 0,
        doneCount: data.data.done_count || 0,
        deleteCount: data.data.deleted_count || 0,
        approveCount: data.data.approve_count || 0,
      });

      setData(data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Use the fetchData function inside the useEffect hook
  useEffect(() => {
    fetchData();
  }, [id, status,searchQuery]);

  const handleBookmark = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/posts/${id}/action`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };

        const postData = {
          type_of: "save",
          url: "",
        };

        const response = await fetch(apiUrl, {
          method: "POST",
          headers,
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        setLocalIsSaved(!localIsSaved);
        console.log("Bookmark action successful");

        // Handle the response or any other logic as needed
      } else {
        console.error("Token not available");
        // Handle the case where the token is not available
      }
    } catch (error) {
      console.error("Error in handleBookMark:", error);
      // Handle the error, show an alert, or perform any other necessary actions
    }
  };

  useEffect(() => {
    setLocalIsSaved(data?.post?.is_saved);
  }, [data?.post?.is_saved]);

  const idToUse = selectedItemId1 ? selectedItemId1 : firstId1;
  const typeToUse = selectedType ? selectedType : firstType1;

  const to =
    "/todo-list" +
    genNewSearchParamString(
      searchParams,
      typeToUse === "user" ? "user_id" : "group_id",
      idToUse
    );

  return (
    <>
      <div className="container">
        <div className="pt-1 ps-3  row">
          <div className=" col-md-12 col-lg-8 ps-4 padding-done text-start ">
            <span className="todos-home" onClick={() => navigate("/posts/new")}>
              Home {" > "}
            </span>

            <Link to="/main-todo">
              <span className="todos-home">To-do {" > "}</span>
            </Link>

            {groupName ? (
              <Link to={to}>
                <span className="todos-home">{groupName}</span>
              </Link>
            ) : (
              <>
                {selectedItemName ? (
                  <Link to={to}>
                    <span className="todos-home">{selectedItemName}</span>
                  </Link>
                ) : (
                  <span className="todos-home">
                    <Link to={to}>{firstTodoName} </Link>
                  </span>
                )}
              </>
            )}

            <span className="todos-home">{" > "}</span>
            <span className="text-break todos-home p-0 text-start">
              {data?.post?.notes}
            </span>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-9 ">
            <div className="row">
              <div className="position-parent">
                <div className="col-12">
                  <ToastContainer ref={toastRef} timeout={4000} />
                  <Tabs
                    defaultActiveKey="still_working"
                    activeKey={activeTab}
                    id="tab-example"
                    className="todonav text-muted mb-1 tab-border"
                    onSelect={handleSelect}
                  >
                    <Tab
                      eventKey="still_working"
                      title={`To do (${count.todoCount})`}
                      className="text-success navlink"
                    >
                      {/* {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                         
                          <StaticBox />
                        </div>
                      ) : (
                        <> */}
                          <div className="input-group mb-1 mt-1  todosearch ">
                            <input
                              type="text"
                              className="form-control ps-4  rounded-start-pill cursor-point shadow-none"
                              placeholder="Search"
                              name=""
                              id="searchTodo"
                              value={searchQuery}
                              onChange={handleSearch}
                            />

                            <div className="input-group-append">
                              <span className="input-group-text greysearchimg cursor-point">
                                <img
                                  src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                                  width="23px"
                                  alt=""
                                  className="cursor-point"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="container text-center">
                            <div className="row pb-2">
                              <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-center">
                                <Tippy content="checkbox">
                                  <input
                                    className="form-check-input custm-checkbox cursor-pointer"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                </Tippy>
                              </div>

                              <div className="col-sm-9 col-9 d-flex align-items-center  p-0">
                                <p className=" notes px-1 pt-1 m-0 fw-medium  text-break text-start">
                                  {data?.post?.notes}
                                </p>
                              </div>
                              <div className="col-sm-2 col-2 d-flex align-items-center justify-content-end">
                                <span
                                  className="pe-2 "
                                  onClick={() => {
                                    navigate(`/posts/${id}/comments`);
                                  }}
                                >
                                  <Tippy content="comments">
                                    {data?.post?.total_comments > 0 ? (
                                      <img
                                        src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                        alt="Green Message"
                                        width="12px"
                                        height="12px"
                                        className="chat cursor-point"
                                      />
                                    ) : (
                                      <img
                                        src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
                                        alt="Grey Message"
                                        width="12px"
                                        height="12px"
                                        className="cursor-point"
                                      />
                                    )}
                                  </Tippy>
                                </span>
                                <span onClick={handleBookmark}>
                                  <Tippy
                                    content={
                                      localIsSaved
                                        ? "bookmark checked"
                                        : "bookmark"
                                    }
                                  >
                                    {localIsSaved ? (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    ) : (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    )}
                                  </Tippy>
                                </span>
                              </div>
                            </div>
                          </div>
                          {count.todoCount === 0 ? (
                            <>
                              <p className="text-center fw-bold text-black heighttodo pt-5">
                                It seems like there are no tasks pending.
                                <span className="d-block">
                                  Check back later for new tasks.
                                </span>
                              </p>
                            </>
                          ) : activeTab === "still_working" ? (
                            <MiniTodo
                              fetchData={fetchData}
                              id={id}
                              sortOrder={sortOrder}
                              sortBy={sortBy}
                              isIconUp={isIconUp}
                            />
                          ) : (
                            <p>still_working</p>
                          )}
                          <div className="addtaskpart py-0 mb-5 mt-3">
                            <form
                              action=""
                              id="AddTask"
                              onSubmit={handleSubmit}
                            >
                              <div
                                className="taskborder pt-0 px-0 pb-1 d-flex align-items-center"
                                // style={{
                                //   display: "flex",
                                //   alignItems: "center",
                                // }}
                              >
                                {/* <a href="#" className="taskbtn">
                    <img
                      src="https://yesdone.com/build/assets/greyplus-2f2d8fa2.png"
                      className=""
                      alt=""
                    />
                  </a> */}
                                <textarea
                                  type="text"
                                  className="form-control add-input px-0 shadow-none  border-0 text-resize"
                                  autoComplete="off"
                                  required
                                  name="title"
                                  onChange={(e) => handleTextChange(e)}
                                  value={title1}
                                  placeholder="+   Name of Task"
                                  // style={{ height: "25px" , resize: "none" }}
                                />
                                {/* Due Date and Calendar */}
                                <div className="position-relative d-flex align-items-center ms-2">
                                  <button
                                    type="button"
                                    className="border-0 text-start calander bg-transparent"
                                    onClick={handleShowCalander}
                                    style={{ position: "relative", zIndex: 2 }}
                                  >
                                    <span className="d-flex justify-content-between align-items-center">
                                      <span className="me-2 custom-fontsize">
                                        Due date :
                                      </span>
                                      <span className="custom-fontsize">
                                        {formattedDate}
                                      </span>
                                    </span>
                                  </button>
                                  <span className="fs-1 ps-2 pe-1 pb-3">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox"
                                      onChange={handleCheckboxChange}
                                      checked={isUrgent}
                                    />
                                  </span>
                                  <span className="text-danger">Urgent</span>
                                  {showCalander ? (
                                    <div
                                      className="position-absolute mt-2 shadow bg-white"
                                      style={{
                                        zIndex: 999,
                                        top: "",
                                        bottom: "30px",
                                        right: "0px",
                                      }}
                                    >
                                      <Calendar
                                        onChange={handleDateChange}
                                        value={selectedDate}
                                        // minDate={currentDate}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="d-flex">
                                <label className="custom-file-upload">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="mediaFile"
                                    name="files"
                                    accept="image/*, video/*"
                                    onChange={handleFileChange}
                                  />
                                  <img
                                    src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                                    alt=""
                                    width="22px"
                                    height="18px"
                                    className="cursor-point"
                                  />
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control add-input shadow-none border-0 text-resize"
                                  autoComplete="off"
                                  name="video_url"
                                  placeholder="Youtube Video"
                                />
                                <button
                                  type="submit"
                                  className="submitclass btn ms-auto"
                                >
                                  <img
                                    src="https://yesdone.com/build/assets/send-99cff475.png"
                                    alt="send img"
                                    width="35px"
                                    height="35px"
                                    className="cursor-point"
                                  />
                                </button>
                              </div>
                              {(thumbnail || file) && (
                                <ImagePreview
                                  file={thumbnail || file}
                                  onDelete={handleFileDelete}
                                />
                              )}
                            </form>
                          </div>

                          <Modal
              show={checkBoxModal}
              onHide={handleCloseCheckboxModal}
              className="d-flex align-items-center justify-content-center"
            >
              <Modal.Header className="fs-1 fw-bold text-center border-0 mx-auto">
                <i className="bi bi-exclamation-circle text-center text-danger modal-icon"></i>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to create an urgent task?
              </Modal.Body>
              <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
                <Button variant="primary" onClick={handleCheckBoxClick}>
                  Yes
                </Button>
                <Button variant="secondary" onClick={handleCloseCheckboxModal}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
                          <ToastContainer ref={toastRef} timeout={4000} />
                        {/* </>
                      )} */}
                    </Tab>
                    <Tab
                      eventKey="approved"
                      title={`Approve (${count.approveCount})`}
                      className="text-success navlink"
                    >
                      {loading &&!searchQuery ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <StaticBox />
                        </div>
                      ) : (
                        <>
                          <div className="input-group mb-1 mt-1  todosearch ">
                            <input
                              type="text"
                              className="form-control ps-4  rounded-start-pill cursor-point shadow-none"
                              placeholder="Search"
                              name=""
                              id="searchTodo"
                              value={searchQuery}
                              onChange={handleSearch}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text greysearchimg cursor-point">
                                <img
                                  src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                                  width="23px"
                                  alt=""
                                  className="cursor-point"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="container  text-center">
                            <div className="row pb-1">
                              <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-center">
                                <Tippy content="checkbox">
                                  <input
                                    className="form-check-input custm-checkbox cursor-pointer"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                </Tippy>
                              </div>

                              <div className="col-sm-9 col-9 d-flex align-items-center  p-0">
                                <p className=" notes px-1 pt-1 m-0 fw-medium  text-break text-start">
                                  {data?.post?.notes}
                                </p>
                              </div>
                              <div className="col-sm-2 col-2 d-flex align-items-center justify-content-end">
                                <span
                                  className="pe-2 "
                                  onClick={() => {
                                    navigate(`/posts/${id}/comments`);
                                  }}
                                >
                                  <Tippy content="comments">
                                    {data?.post?.total_comments > 0 ? (
                                      <img
                                        src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                        alt="Green Message"
                                        width="12px"
                                        height="12px"
                                        className="chat cursor-point"
                                      />
                                    ) : (
                                      <img
                                        src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
                                        alt="Grey Message"
                                        width="12px"
                                        height="12px"
                                        className="cursor-point"
                                      />
                                    )}
                                  </Tippy>
                                </span>
                                <span onClick={handleBookmark}>
                                  <Tippy
                                    content={
                                      localIsSaved
                                        ? "bookmark checked"
                                        : "bookmark"
                                    }
                                  >
                                    {localIsSaved ? (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    ) : (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    )}
                                  </Tippy>
                                </span>
                              </div>
                            </div>
                          </div>
                          {count.approveCount === 0 ? (
                            <p className="text-center fw-bold text-black heighttodo pt-5">
                              It seems like there are no tasks pending.
                              <span className="d-block">
                                Check back later for new tasks.
                              </span>
                            </p>
                          ) : activeTab === "approved" ? (
                            <MiniApprove
                              fetchData={fetchData}
                              Post_id={id}
                              toastRef={toastRef}
                            />
                          ) : (
                            <p>done</p>
                          )}
                        </>
                       )}
                    </Tab>
                    <Tab
                      eventKey="done"
                      title={`Done (${count.doneCount})`}
                      className="text-success navlink"
                    >
                      {loading &&!searchQuery? (
                        <div className="d-flex align-items-center justify-content-center">
                          <StaticBox />
                        </div>
                      ) : (
                        <>
                          <div className="input-group mb-1 mt-1  todosearch ">
                            <input
                              type="text"
                              className="form-control ps-4  rounded-start-pill cursor-point shadow-none"
                              placeholder="Search"
                              name=""
                              id="searchTodo"
                              value={searchQuery}
                              onChange={handleSearch}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text greysearchimg cursor-point">
                                <img
                                  src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                                  width="23px"
                                  alt=""
                                  className="cursor-point"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="container  text-center">
                            <div className="row pb-1">
                              <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-center">
                                <Tippy content="checkbox">
                                  <input
                                    className="form-check-input custm-checkbox cursor-pointer"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                </Tippy>
                              </div>

                              <div className="col-sm-9 col-9 d-flex align-items-center  p-0">
                                <p className=" notes px-1 pt-1 m-0 fw-medium  text-break text-start">
                                  {data?.post?.notes}
                                </p>
                              </div>
                              <div className="col-sm-2 col-2 d-flex align-items-center justify-content-end">
                                <span
                                  className="pe-2 "
                                  onClick={() => {
                                    navigate(`/posts/${id}/comments`);
                                  }}
                                >
                                  <Tippy content="comments">
                                    {data?.post?.total_comments > 0 ? (
                                      <img
                                        src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                        alt="Green Message"
                                        width="12px"
                                        height="12px"
                                        className="chat cursor-point"
                                      />
                                    ) : (
                                      <img
                                        src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
                                        alt="Grey Message"
                                        width="12px"
                                        height="12px"
                                        className="cursor-point"
                                      />
                                    )}
                                  </Tippy>
                                </span>
                                <span onClick={handleBookmark}>
                                  <Tippy
                                    content={
                                      localIsSaved
                                        ? "bookmark checked"
                                        : "bookmark"
                                    }
                                  >
                                    {localIsSaved ? (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    ) : (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    )}
                                  </Tippy>
                                </span>
                              </div>
                            </div>
                          </div>
                          {count.doneCount === 0 ? (
                            <p className="text-center fw-bold text-black heighttodo pt-5">
                              It seems like there are no tasks pending.
                              <span className="d-block">
                                Check back later for new tasks.
                              </span>
                            </p>
                          ) : activeTab === "done" ? (
                            <MiniDone
                              fetchData={fetchData}
                              Post_id={id}
                              toastRef={toastRef}
                            />
                          ) : (
                            <p>done</p>
                          )}
                        </>
                       )}
                    </Tab>
                    <Tab
                      eventKey="deleted"
                      title={`Deleted (${count.deleteCount})`}
                      className="text-success navlink"
                    >
                      {loading &&!searchQuery? (
                        <div className="d-flex align-items-center justify-content-center">
                          <StaticBox />
                        </div>
                      ) : (
                        <>
                          <div className="input-group mb-1 mt-1  todosearch ">
                            <input
                              type="text"
                              className="form-control ps-4  rounded-start-pill cursor-point shadow-none"
                              placeholder="Search"
                              name=""
                              id="searchTodo"
                              value={searchQuery}
                              onChange={handleSearch}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text greysearchimg cursor-point">
                                <img
                                  src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                                  width="23px"
                                  alt=""
                                  className="cursor-point"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="container  text-center">
                            <div className="row pb-2">
                              <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-center">
                                <Tippy content="checkbox">
                                  <input
                                    className="form-check-input custm-checkbox cursor-pointer"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                </Tippy>
                              </div>

                              <div className="col-sm-9 col-9 d-flex align-items-center  p-0">
                                <p className=" notes px-1 pt-1 m-0 fw-medium  text-break text-start">
                                  {data?.post?.notes}
                                </p>
                              </div>
                              <div className="col-sm-2 col-2 d-flex align-items-center justify-content-end">
                                <span
                                  className="pe-2 "
                                  onClick={() => {
                                    navigate(`/posts/${id}/comments`);
                                  }}
                                >
                                  <Tippy content="comments">
                                    {data?.post?.total_comments > 0 ? (
                                      <img
                                        src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                        alt="Green Message"
                                        width="12px"
                                        height="12px"
                                        className="chat cursor-point"
                                      />
                                    ) : (
                                      <img
                                        src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
                                        alt="Grey Message"
                                        width="12px"
                                        height="12px"
                                        className="cursor-point"
                                      />
                                    )}
                                  </Tippy>
                                </span>
                                <span onClick={handleBookmark}>
                                  <Tippy
                                    content={
                                      localIsSaved
                                        ? "bookmark checked"
                                        : "bookmark"
                                    }
                                  >
                                    {localIsSaved ? (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    ) : (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    )}
                                  </Tippy>
                                </span>
                              </div>
                            </div>
                          </div>
                          {count.deleteCount === 0 ? (
                            <p className="text-center fw-bold text-black heighttodo pt-5">
                              It seems like there are no tasks pending.
                              <span className="d-block">
                                Check back later for new tasks.
                              </span>
                            </p>
                          ) : activeTab === "deleted" ? (
                            <MiniDelete fetchData={fetchData} />
                          ) : (
                            <p>deleted</p>
                          )}
                        </>
                       )}
                    </Tab>
                  </Tabs>
                </div>
                {activeTab === "still_working" && (
                  <>
                    <div className="position-child">
                      <span>Due Date</span>
                      <button
                        type="button"
                        className="btn p-0 ms-2"
                        onClick={handleIconClick}
                        style={{ background: "transparent", border: "none" }}
                      >
                        {isIconUp ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-3  ">
            <DateAndSort
              handleSortChange={handleSortChange}
              dateField={dateField}
              alphabetical={notes}
              createdat={createdat}
              Updatedat={Updatedat}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniPost;
