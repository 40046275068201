import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import API_BASE_URL from "../../Config";
import "./Header.css";
import "./Model.css";
import logo from "../../images/app_logo-02d8f0cb.png";
import settings from "../../images/settings.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
  getTodoReqParams,
} from "../../utils/index";
import { useSearchParams } from "react-router-dom"; // Import the fetchPostData function
import { genNewSearchParamString } from "../../utils";
// import { fetchUserData } from "../../Api/User";
import { useAuth } from "../AuthContext/AuthContext";
import Loginimage from "../../images/app_logo-02d8f0cb.png";
import Tippy from "@tippyjs/react";
import { useTodo } from "../AuthContext/TodoContext";
import UserContext from "../AuthContext/ChatContext";
import { useLocation } from "react-router-dom";
import { useTodoData } from "../AuthContext/TodoDataContext";
const Header = ({ toggleSidebar }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const currentPath = location.pathname;
  // console.log("currentpath" , currentPath);
  const isTodoCommentsPage = currentPath.includes("/todo-comments");
  console.log("currentpath", currentPath, isTodoCommentsPage);
  const { setSelectedItemName } = useTodo();
  const { selectedUserId1, setGroupName, groupName } = useContext(UserContext);
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showGetModal, setShowGetModal] = useState(false);
  const [todoCount, setTodoCount] = useState(0);
  const [deleteCount, setDeleteCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const { todoData, setTodoData } = useTodoData();
  const [profiles, setProfiles] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const handleOpenSignUp = () => {
    setShowSignUp(true);
  };
  const notification = localStorage.getItem("notification_count");
  const handleCloseFirstModal = () => {
    setShowFirstModal(false);
    handleCloseSignUp();
  };
  const handleOpenFirstModal = () => {
    console.log("clicked", setShowFirstModal);
    setShowFirstModal(true);
  };
  const handleOpenSecondModal = () => {
    setShowFirstModal(false);
    setShowSecondModal(true);
  };
  const handleCloseSecondModal = () => {
    setShowSecondModal(false);
  };
  const goback = () => {
    setShowFirstModal(true);
    setShowSecondModal(false);
  };
  const goback1 = () => {
    setShowFirstModal(false);
    setShowSecondModal(true);
    setShowSignUp(false);
  };
  const handleCloseSignUp = () => {
    setShowSignUp(false);
    setShowFirstModal(false);
    setShowSecondModal(false);
  };

  const GetModal = () => {
    setShowGetModal(true);
  };
  const handleCloseGetModal = () => {
    setShowGetModal(false);
  };
  const navigate = useNavigate();
  const { isLoggedIn, login, logout } = useAuth();
  console.log("isLoggedIn", isLoggedIn);
  const handleSignOut = () => {
    // Clear token and user id from local storage

    logout();
    setProfiles(null);
    navigate("/");
    setSelectedItemName(null);
    setGroupName(null);
    setTodoData({
      todoCount: 0,
      approvedCount: 0,
      deleteCount: 0,
      posts: [],
    });

    console.log("Signing out...");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://beta.yesdone.com/api/v1/register",
        {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          password: formData.password,
        }
      );

      // Handle successful registration
      console.log("Registration successful:", response.data);
      // Redirect to login page upon successful registration
      navigate("/login");
    } catch (error) {
      // Handle registration errors
      if (error.response) {
        // Server responded with a status code outside of 2xx range
        setError(error.response.data.message);
      } else {
        // Something went wrong with the request
        console.error("Registration failed:", error.message);
        setError("Registration failed. Please try again later.");
      }
    }
  };

  const selectedType = localStorage.getItem("selectedType");
  const selectedItemId1 = localStorage.getItem("selectedItemId");
  const firstId1 = localStorage.getItem("firstId");
  const firstType1 = localStorage.getItem("firstType");

  const handleHome = async (e) => {
    const idToUse = firstId1 ? firstId1 : selectedItemId1;
    const typeToUse = firstType1 ? firstType1 : selectedType;

    // navigate("/todo-list" +
    // genNewSearchParamString(
    //   searchParams,
    //   typeToUse === "user" ? "user_id" : "group_id",
    //   idToUse
    // ))
    navigate("posts/new");
    setSelectedItemName(null);
    setGroupName(null);
  };

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("Token not found in local storage");
        return null;
      }

      const response = await fetch(`${API_BASE_URL}/api/v1/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("data", data);
        setProfiles(data?.data);
      } else {
        console.error("Failed to fetch user data:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [isLoggedIn]);

  // const handleCloseFirstModal = () => {
  //   // Close the signup modal
  //   handleCloseSignUp();
  // };
  const profile = localStorage.getItem("profile");
  const firstName = localStorage.getItem("first_name");
  return (
    <div
      className={`header-border pb-2 over-flowheader ${
        isTodoCommentsPage ? "fixed-header" : ""
      }`}
    >
      <div className="row d-flex justify-content-center align-items-center">
        <div className=" col-lg-2  col-md-4 col-sm-12  text-center">
          <img
            src={logo}
            className="donelogo pt-3"
            alt="images-logo"
            onClick={handleHome}
          />
        </div>
        <div className="col-6  col-lg-7  header-text  py-3 px-0">
          <div className=" d-flex justify-content-center align-items-center">
            <p className="mb-0 me-4">
              <span className="text-dark  ">
                The hottest deals from Craigslist, OfferUp, Facebook
                Marketplace, and hundreds of other sites, <b>delivered</b>
              </span>
            </p>
            <span className="">
              <button
                href="#"
                className="btn button-green py-1 px-4 "
                onClick={GetModal}
              >
                Get the App
              </button>
              <Modal
                show={showGetModal}
                onHide={handleCloseGetModal}
                className="d-flex align-items-center "
              >
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body className="text-center pt-0 ">
                  <h2 className="modal-title  pt-0  text-center">
                    Get The App
                  </h2>
                  <div className="image-modal mx-auto  py-2">
                    <img
                      src="https://dtzxzoe2ldz0i.cloudfront.net/yesdone/YesDone-Web used-46.png"
                      width="330px"
                      className="img-fluid "
                      alt=""
                    />
                  </div>
                  <p className="mb-1 get ">
                    Post your items in a little as 30 seconds
                  </p>
                  <form>
                    <label className="footer-mdl">
                      We`ll text you a link to download the app
                    </label>
                    <input
                      type="text"
                      placeholder="(###)-###-####"
                      className="get-form form-control  get-form1"
                    />
                  </form>
                  <div className="py-3 ">
                    <button
                      type="submit"
                      class="form-control  get-form1  bg-success text-white "
                    >
                      Send Link
                    </button>
                  </div>
                  <div className="pb-3 ">
                    <img
                      src="https://dtzxzoe2ldz0i.cloudfront.net/yesdone/YesDone-Web used-48.png"
                      className=" w-50 pe-1 get-image "
                    />
                    <img
                      src="https://dtzxzoe2ldz0i.cloudfront.net/yesdone/YesDone-Web used-49.png"
                      className="w-50 get-image"
                    />
                  </div>
                </Modal.Body>
              </Modal>
            </span>
          </div>
        </div>
        {/* <div className=" col-lg-4 col-xxl-3 col-md-6 col-sm-12 px-2 response-right text-start">
          <ul className="nav response pt-3 pb-2">
            <Link to="/posts/new">
              <li className="d-flex align-items-center">
                <span className="btn butn-green1 fw-semibold mx-1  px-3">
                  + Post
                </span>
              </li>
            </Link>
            <Link to="/posts/creategroup">
              <li className="d-flex align-items-center px-2">
                <span className="btn butn-green1 fw-semibold  px-2">
                  + Create Group
                </span>
              </li>
            </Link>
            {isLoggedIn ? (
              <Link to="/chat">
                <li className="d-flex align-items-center px-2">
                  <Tippy content="chat">
                    <i className="bi bi-chat-left-fill fs-4 px-2 chat-icon">
                      <span>{notification}</span>
                    </i>
                  </Tippy>
                </li>
              </Link>
            ) : null}
            <div className="drop-position pe-2">
              <li className="d-flex align-items-center ps-2  border border-2 rounded-pill cursor-pointer">
                {isLoggedIn ? (
                  <>
                    <div className="dropdown  pe-1">
                      <button
                        type="button"
                        className="btn dropdown-toggle butn-toggle border-0 p-0"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-bars pe-2 profile-icon"
                          aria-hidden="true"
                        ></i>
                        {isLoggedIn && profile ? (
                          <span className=" ">
                            <img
                              src={profile}
                              className="rounded-circle "
                              alt="profile_logo  "
                              width="26px"
                              height="26px"
                            />
                          </span>
                        ) : (
                          <span className="rounded-circle img-profile profile-initial px-2">
                            {firstName ? firstName.charAt(0).toUpperCase() : ""}
                          </span>
                        )}
                      </button>
                      <div className=" menu-position">
                        <ul className="dropdown-menu ">
                          {isLoggedIn ? (
                            <>
                              <Link to="/user/profile">
                                <li className="dropdown-item border-bottom menu-positionli">
                                  Profile Details
                                </li>
                              </Link>
                              <Link to="/changepassword">
                                <li className="border-bottom menu-positionli dropdown-item">
                                  Change Password
                                </li>
                              </Link>
                              <li>
                                <button
                                  className="dropdown-item menu-positionli"
                                  onClick={handleSignOut}
                                >
                                  Sign Out
                                </button>
                              </li>
                            </>
                          ) : (
                            <li>
                              <a className="dropdown-item" href="#">
                                Login
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <span
                    className="d-flex flex-row align-items-center"
                    onClick={handleOpenFirstModal}
                  >
                    <span className="d-flex align-items-center link-body-emphasis text-decoration-none profileuser">
                      <i
                        className="fa fa-bars pe-2 profile-icon"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span className=" ms-1 pe-1">
                      <img
                        src="https://dtzxzoe2ldz0i.cloudfront.net/yesdone/YesDone-Web-10.png"
                        alt="User"
                        className="todo_user pe-1 img-fluid"
                      />
                    </span>
                  </span>
                )}
              </li>
            </div>
            {profiles?.id === 1 ? (
              <>
                <Tippy content="settings">
                  <li
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        "https://yesdone.com/users?openmenu=1")
                    }
                  >
                    <img src={settings} height="25px" alt="settings icon" />
                  </li>
                </Tippy>
              </>
            ) : null}
          </ul>
          {isLoggedIn && profiles ? (
            <div className="text-center pt-1 text-dark searchtodo name_Custum">
              <span>{profiles?.name}</span>
              <span className="mx-1">,</span>
              <span>ID : {profiles?.id}</span>
            </div>
          ) : null}

          <Modal
            show={showFirstModal}
            onHide={handleCloseFirstModal}
            className="d-flex align-items-center"
          >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body className="text-center pt-0 ">
              <h2 className="modal-title title pt-0 fs-4 text-center">
                Sign up/Log in
              </h2>
              <div className="image-modal mx-auto  py-3">
                <img
                  src={Loginimage}
                  width="170px"
                  className="logoimage"
                  alt=""
                />
              </div>
              <div className="row button-modal modal-btns button-modal1 ps-5 ">
                <div className="col-12 modal-btns modal-btns1 ">
                  <button className="facebook-img border-0 my-3">
                    <img
                      src="https://dtzxzoe2ldz0i.cloudfront.net/yesdone/facebook.png"
                      className="img-model1"
                      alt=""
                    />
                    <span className="text-facebook">
                      Continue With Facebook
                    </span>
                  </button>
                  <button className="google-img border-0 my-3">
                    <img
                      src="https://dtzxzoe2ldz0i.cloudfront.net/yesdone/google.png"
                      className="img-model2"
                      alt=""
                    />
                    <span className="text-google">Continue With Google</span>
                  </button>
                  <button className="mail-button bg-transparent  my-3">
                    <img
                      src="https://dtzxzoe2ldz0i.cloudfront.net/yesdone/apple.png"
                      className="img-model3"
                      alt=""
                    />
                    <span className="text-apple">Continue With Apple</span>
                  </button>
                  <button
                    className="mail-button bg-transparent text-decoration-none  my-3"
                    onClick={handleOpenSecondModal}
                  >
                    <img
                      src="https://dtzxzoe2ldz0i.cloudfront.net/yesdone/message.png"
                      className="img-model4"
                      alt=""
                    />
                    <span className="text-mail">Continue With Email</span>
                  </button>
                </div>
              </div>
              <div className="footer-mdl fw-medium py-2 px-3">
                <p>
                  By tapping `Sign in` you agree to our
                  <span className="text-decoration-underline ps-1">
                    Terms.
                  </span>{" "}
                  Learn how we process your data in our
                  <span className="text-decoration-underline ps-1">
                    Privacy Policy.
                  </span>{" "}
                  And
                  <span className="text-decoration-underline ps-1">
                    Cookies Policy.
                  </span>
                </p>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={showSecondModal}
            onHide={handleCloseSecondModal}
            className="d-flex align-items-center"
          >
            <Modal.Header closeButton className="border-0">
              <span onClick={goback}>
                <i
                  className="fa fa-angle-left text-dark"
                  aria-hidden="true"
                ></i>
              </span>
            </Modal.Header>
            <Modal.Body className="text-center pt-0 ">
              <h2 className="modal-title title pt-0 fs-4 text-center">
                Sign up/Log in
              </h2>
              <div className="image-modal mx-auto  py-3">
                <img
                  src={Loginimage}
                  width="170px"
                  className="logoimage"
                  alt=""
                />
              </div>
              <div className="row button-modal modal-btns  login-paddingl ps-5 ">
                <div className="col-12 modal-btns ">
                  <button
                    className="mail-button fb-image  my-3"
                    onClick={handleOpenSignUp}
                  >
                    <span className="text-apple mx-auto">Sign Up</span>
                  </button>
                  <Link to="/login">
                    <button
                      className="mail-button bg-transparent  my-3"
                      onClick={handleCloseFirstModal}
                    >
                      <span className="text-apple mx-auto">Login</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="footer-mdl fw-medium py-2 px-3">
                <p>
                  By tapping `Sign in` you agree to our
                  <span className="text-decoration-underline ps-1">
                    Terms.
                  </span>{" "}
                  Learn how we process your data in our
                  <span className="text-decoration-underline ps-1">
                    Privacy Policy.
                  </span>{" "}
                  And
                  <span className="text-decoration-underline ps-1">
                    Cookies Policy.
                  </span>
                </p>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={showSignUp}
            onHide={handleCloseSignUp}
            className="d-flex align-items-center modal-fade"
          >
            <Modal.Header
              closeButton
              className="border-0 "
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span onClick={goback1}>
                <i
                  className="fa fa-angle-left text-dark"
                  aria-hidden="true"
                ></i>
              </span>
            </Modal.Header>
            <Modal.Body className="text-center pt-0 ">
              <form onSubmit={handleSubmit}>
                <div className="px-lg-4">
                  <h2 className="modal-title title pt-0 fs-4 text-center">
                    Sign up/Log in
                  </h2>
                  <div className="image-modal mx-auto  py-3">
                    <img
                      src="/static/media/app_logo-02d8f0cb.4fcd3eeec307f86cddc7.png"
                      width="170px"
                      className="logoimage"
                      alt=""
                    />
                  </div>
                  <div className="px-4">
                    <form>
                      <div className="d-flex  flex-column  ">
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          className="signupform rounded-pill px-3 "
                          placeholder="First Name"
                        />
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          className="signupform rounded-pill px-3 "
                          placeholder="Last Name"
                        />
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className="signupform  rounded-pill px-3 "
                          placeholder="Email address"
                        />
                        <input
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="signupform  rounded-pill px-3 "
                          placeholder="password"
                        />
                      </div>
                    </form>
                  </div>
                  <p className="signup-p pt-3 fw-medium">
                    By tapping `Sign in` you agree to our
                    <span className="text-decoration-underline">
                      Terms.
                    </span>{" "}
                    Learn how we process your data in our
                    <span className="text-decoration-underline">
                      Privacy Policy.
                    </span>{" "}
                    and
                    <span className="text-decoration-underline">
                      Cookies Policy.
                    </span>
                  </p>
                  <button
                    className="login-btn rounded-pill my-3"
                    onClick={handleCloseFirstModal}
                  >
                    <span className=" ">Register</span>
                  </button>
                  <Link to="/login">
                    <p className="= mb-0 signup1-p  fw-medium ">
                      Already have a account? Log in
                    </p>
                  </Link>
                  <div className="footer-mdl  fw-medium pt-2 ">
                    <p className="signup-p ">
                      By tapping `Sign in` you agree to our
                      <span className="text-decoration-underline">
                        Terms.
                      </span>{" "}
                      Learn how we process your data in our
                      <span className="text-decoration-underline">
                        Privacy Policy.
                      </span>
                    </p>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div> */}
        <div className="col-lg-3 col-xxl-3 col-md-4 col-sm-12 px-2 text-start response-right">
          <ul className="nav response pt-3 pb-2 d-flex align-items-center flex-wrap">
            <Link to="/posts/new">
              <li className="d-flex align-items-center">
                <span className="btn butn-green1 fw-semibold mx-1 px-3">
                  + Post
                </span>
              </li>
            </Link>
            <Link to="/posts/creategroup">
              <li className="d-flex align-items-center px-2">
                <span className="btn butn-green1 fw-semibold px-2">
                  + Create Group
                </span>
              </li>
            </Link>
            {isLoggedIn && (
              <Link to="/chat">
                <li className="d-flex align-items-center px-2">
                  <Tippy content="chat">
                    <i className="bi bi-chat-left-fill fs-4 px-2 chat-icon">
                      <span>{notification}</span>
                    </i>
                  </Tippy>
                </li>
              </Link>
            )}
            <div className="drop-position pe-2">
              <li className="d-flex align-items-center ps-2 border border-2 rounded-pill cursor-pointer">
                {isLoggedIn ? (
                  <div className="dropdown pe-1">
                    <button
                      type="button"
                      className="btn dropdown-toggle butn-toggle border-0 p-0"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i
                        className="fa fa-bars pe-2 profile-icon"
                        aria-hidden="true"
                      ></i>
                      {profile ? (
                        <img
                          src={profile}
                          className="rounded-circle"
                          alt="profile_logo"
                          width="26"
                          height="26"
                        />
                      ) : (
                        <span className="rounded-circle img-profile profile-initial px-2">
                          {firstName?.charAt(0).toUpperCase()}
                        </span>
                      )}
                    </button>
                    <ul className="dropdown-menu menu-position">
                      <Link to="/user/profile">
                        <li className="dropdown-item border-bottom menu-positionli">
                          Profile Details
                        </li>
                      </Link>
                      <Link to="/changepassword">
                        <li className="border-bottom menu-positionli dropdown-item">
                          Change Password
                        </li>
                      </Link>
                      <li>
                        <button
                          className="dropdown-item menu-positionli"
                          onClick={handleSignOut}
                        >
                          Sign Out
                        </button>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <span
                    className="d-flex flex-row align-items-center"
                    onClick={handleOpenFirstModal}
                  >
                    <i
                      className="fa fa-bars pe-2 profile-icon"
                      aria-hidden="true"
                    ></i>
                    <img
                      src="https://dtzxzoe2ldz0i.cloudfront.net/yesdone/YesDone-Web-10.png"
                      alt="User"
                      className="todo_user pe-1 img-fluid"
                    />
                  </span>
                )}
              </li>
            </div>
            {profiles?.id === 1 && (
              <Tippy content="settings">
                <li
                  className="d-flex align-items-center cursor-pointer px-2"
                  onClick={() =>
                    (window.location.href =
                      "https://yesdone.com/users?openmenu=1")
                  }
                >
                  <img src={settings} height="25" alt="settings icon" />
                </li>
              </Tippy>
            )}
          </ul>
          {isLoggedIn && profiles && (
            <div >
              <div className="text-center p-2 text-dark searchtodo name_Custum">
              <span>{profiles?.name}</span>
              <span className="mx-1">,</span>
              <span>ID : {profiles?.id}</span>
            </div>
            </div>
           
          )}
        </div>
      </div>
      <div></div>

      <div></div>
      {/* </> */}
    </div>
  );
};

export default Header;
