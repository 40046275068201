// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

.popup-content {
    background: #fff;
    padding: 30px;
    max-width: 89%;
    max-height: 89%;
    overflow: auto;
    z-index: 1001;
}

.element-behind-popup {
    z-index: 999;
}
.media-container {
    position: relative;
}

.close-button {
    position: absolute;
    top: -60px;
    right: -24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: red;
}


.responsive-iframe {
    width: 100%;
    height: 100%;
    border: none;
    object-fit: cover;
  }
  

.close-button:hover {
    color: red;
}
@media screen  and (max-width:576px){
    .popup-vi video , .popup-vi img{
        width: 300px;
     }
     .popup-content {
      padding: 25px;
}}
.image-container {
    overflow: hidden; /* Hide any overflow content */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/popup/Popup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;AAEF;IACI,gBAAgB;IAChB,aAAa;IACb,cAAc;IACd,eAAe;IACf,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,eAAe;IACf,UAAU;AACd;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,iBAAiB;EACnB;;;AAGF;IACI,UAAU;AACd;AACA;IACI;QACI,YAAY;KACf;KACA;MACC,aAAa;AACnB,CAAC;AACD;IACI,gBAAgB,EAAE,8BAA8B;EAClD","sourcesContent":[".popup-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.8);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n\n.popup-content {\n    background: #fff;\n    padding: 30px;\n    max-width: 89%;\n    max-height: 89%;\n    overflow: auto;\n    z-index: 1001;\n}\n\n.element-behind-popup {\n    z-index: 999;\n}\n.media-container {\n    position: relative;\n}\n\n.close-button {\n    position: absolute;\n    top: -60px;\n    right: -24px;\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    font-size: 20px;\n    color: red;\n}\n\n\n.responsive-iframe {\n    width: 100%;\n    height: 100%;\n    border: none;\n    object-fit: cover;\n  }\n  \n\n.close-button:hover {\n    color: red;\n}\n@media screen  and (max-width:576px){\n    .popup-vi video , .popup-vi img{\n        width: 300px;\n     }\n     .popup-content {\n      padding: 25px;\n}}\n.image-container {\n    overflow: hidden; /* Hide any overflow content */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
