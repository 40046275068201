import React from "react";
import "./Todo.css";
const StaticBox = () => {
  return (
   
    <div className="container text-center">
      {/* <div className=" box mb-4"></div> */}
      <div className="row border-bottom pb-3">
        <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-evenly">
          <div className=" d-flex align-items-center pe-3"></div>
          <div className=" d-flex align-items-center check-static"></div>
        </div>

        <div className="col-sm-9 col-8 d-flex align-items-center notes">
          <span className="d-flex align-items-center pe-2 profilestatic1">
               <div className='row mt-4 pt-3 ps-4'>
      <div className=' date-static'></div>
      </div>
          </span>
        </div>
        <div className="col-sm-2 col-3 d-flex align-items-center justify-content-evenly pt-3">
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
        </div>
      </div>
      <div className="row border-bottom pb-3">
        <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-evenly">
          <div className="d-flex align-items-center pe-3"></div>
          <div className=" d-flex align-items-center check-static"></div>
        </div>

        <div className="col-sm-9 col-8 d-flex align-items-center notes">
          <span className="d-flex align-items-center pe-2 profilestatic1">
               <div className='row mt-4 pt-3 ps-4'>
      <div className=' date-static'></div>
      </div>
          </span>
        </div>
        <div className="col-sm-2 col-3 d-flex align-items-center justify-content-evenly  pt-3">
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
        </div>
      </div>
      <div className="row border-bottom pb-3">
        <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-evenly">
          <div className="d-flex align-items-center pe-3"></div>
          <div className="d-flex align-items-center check-static"></div>
        </div>

        <div className="col-sm-9 col-8 d-flex align-items-center notes">
          <span className="d-flex align-items-center pe-2 profilestatic1">
               <div className='row mt-4 pt-3 ps-4'>
      <div className=' date-static'></div>
      </div>
          </span>
        </div>
        <div className="col-sm-2 col-3 d-flex align-items-center  justify-content-evenly pt-3">
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
        </div>
      </div>
      <div className="row border-bottom pb-3">
        <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-evenly">
          <div className=" d-flex align-items-center pe-3"></div>
          <div className=" d-flex align-items-center check-static"></div>
        </div>

        <div className="col-sm-9 col-8 d-flex align-items-center notes">
          <span className="d-flex align-items-center pe-2 profilestatic1">
               <div className='row mt-4 pt-3 ps-4'>
      <div className=' date-static'></div>
      </div>
          </span>
        </div>
        <div className="col-sm-2 col-3 d-flex align-items-center justify-content-evenly pt-3">
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
        </div>
      </div>
      <div className="row border-bottom pb-3">
        <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-evenly">
          <div className=" d-flex align-items-center pe-3"></div>
          <div className="d-flex align-items-center check-static"></div>
        </div>

        <div className="col-sm-9 col-8 d-flex align-items-center notes">
          <span className="d-flex align-items-center pe-2 profilestatic1">
               <div className='row mt-4 pt-3 ps-4'>
      <div className=' date-static'></div>
      </div>
          </span>
        </div>
        <div className="col-sm-2 col-3 d-flex align-items-center justify-content-evenly pt-3">
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
        </div>
      </div>
      <div className="row border-bottom pb-3">
        <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-evenly">
          <div className=" d-flex align-items-center pe-3"></div>
          <div className=" d-flex align-items-center check-static"></div>
        </div>

        <div className="col-sm-9 col-8 d-flex align-items-center notes">
          <span className="d-flex align-items-center pe-2 profilestatic1">
               <div className='row mt-4 pt-3 ps-4'>
      <div className=' date-static'></div>
      </div>
          </span>
        </div>
        <div className="col-sm-2 col-3 d-flex align-items-center justify-content-evenly pt-3">
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
        </div>
      </div>
      <div className="row border-bottom pb-3">
        <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-evenly">
          <div className=" d-flex align-items-center pe-3"></div>
          <div className=" d-flex align-items-center check-static"></div>
        </div>

        <div className="col-sm-9 col-8 d-flex align-items-center notes">
          <span className="d-flex align-items-center pe-2 profilestatic1">
               <div className='row mt-4 pt-3 ps-4'>
      <div className=' date-static'></div>
      </div>
          </span>
        </div>
        <div className="col-sm-2 col-3 d-flex align-items-center justify-content-evenly pt-3">
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
        </div>
      </div>
      <div className="row border-bottom pb-3">
        <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-evenly">
          <div className=" d-flex align-items-center pe-3"></div>
          <div className=" d-flex align-items-center check-static"></div>
        </div>

        <div className="col-sm-9 col-8 d-flex align-items-center notes">
          <span className="d-flex align-items-center pe-2 profilestatic1">
               <div className='row mt-4 pt-3 ps-4'>
      <div className=' date-static'></div>
      </div>
          </span>
        </div>
        <div className="col-sm-2 col-3 d-flex align-items-center justify-content-evenly pt-3">
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
          <span className="pe-1 icon-padding check-static"></span>
        </div>
      </div>
    </div>
  );
};

export default StaticBox;
