import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Comments.css";
import { Modal, Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import ImagePreview from "../../utils/image-preview";
import ImageModal from "./ImageModal";
import API_BASE_URL from "../../Config";
import uplading from '../../images/uploading.gif';
import sendLoader from  '../../images/gif-loader.gif';
import ChatWindowStatic from "../chat/ChatWindowStatic";
import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
  getTodoReqParams,
} from "../../utils/index";
import LoadingImg from "../../images/loading.webp";
import { Link } from "react-router-dom";
const Comments = () => {
  const [comments, setComments] = useState({});
  const [comments1, setComments1] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(2);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [textcopyId, setTextCopyId] = useState(null);
  const [isCopiedtext, setIsCopiedText] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentValue, setCommentValue] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const playVideo = (videoId) => {
    if (currentVideo && currentVideo !== videoId) {
      // Pause the currently playing video if another video is clicked
      const currentVideoElement = document.getElementById(currentVideo);
      if (currentVideoElement) {
        currentVideoElement.pause();
      }
    }
    setCurrentVideo(videoId);
  };
  const handlePlay = (event) => {
    const videoId = event.target.id;
    playVideo(videoId);
  };

  const handleFileChange = async (event) => {
    setIsUploading(true);
    setThumbnail(null);
    const file = event.target.files[0];

    if (file) {
      try {
        if (file.type.startsWith("image")) {
          const compressedImage = await compressImage(file);
          setFile(compressedImage);
          setIsUploading(false);
        } else if (file.type.startsWith("video")) {
          const thumbnailFile = await videoThumbnail(file);
          const compressedThumbnail = await compressImage(thumbnailFile);
          setThumbnail(compressedThumbnail);
        } else {
          console.error("Unsupported file type");
        }
      } catch (error) {
        console.error("File processing error:", error);
      }
    }



  };

  const handleFileDelete = () => {
    setFile(null);
    setThumbnail(null);
  };
  const handleOpenEdit = (editId, comment, type) => {
    console.log(editId);
    setEditId(editId);
    setEditMode(true);
    if (type === "task" || type === "text") {
      setInputValue(comment);
    } else {
      setInputValue(comment.split("|&|")[1]);
    }
    setCommentValue(comment);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = (todoId) => {
    setSelectedId(todoId);
    console.log(todoId);
    setShowModal(true);
  };

  const navigate = useNavigate();
  const { postid } = useParams();
  console.log(postid);
  const dataLength = comments.comments;
  const userId = localStorage.getItem("user_id");
  console.log("user logged in", userId);

  async function getComments() {
    try {
      setLoader(true);
      let token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const apiUrl = `${API_BASE_URL}/api/v1/comments?post_id=${postid}`;
      const fullUrl = `${apiUrl}&per_page=10&page=${1}`;
      const response = await fetch(fullUrl, {
        method: "GET",
        headers,
      });
      if (response.ok) {
        const responseData = await response.json();
        console.log("API response:", responseData.data.pagination);
        setComments(responseData.data);
        // console.log("responsedata",responseData);

        // If it's the first page, set comments directly
        setComments1(responseData.data.comments);
      } else {
        throw new Error(`API request failed with status ${response.status}`);
      }
      setLoader(false);
    } catch (error) {
      console.error("Error in API call:", error.message);
      throw error;
    }
  }
  console.log("comments", currentPage);
  async function handleDeleteMini() {
    try {
      const storedToken = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${storedToken}`,
        "Content-Type": "application/json",
      };
      const apiUrl = `${API_BASE_URL}/api/v1/comments/${selectedId}`;

      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers,
      });
      if (response.ok) {
        const responseData = await response.json();
        console.log("API response:", responseData);
        setSelectedId(null);
        setShowModal(false);
        getComments();
        return responseData;
      } else {
        throw new Error(`API request failed with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error in API call:", error.message);
      throw error;
    }
  }
  const handleTextareaChange = (e) => {
    setCommentText(e.target.value);
  };
  const handleChange = (value) => {
    setInputValue(value);
  };
  const handleSubmit = async (e, id, type) => {
    e.preventDefault();
    setIsLoading(true); // Start the sending loader

    try {
      let updatedComment;
      if (type === "task" || type === "text") {
        updatedComment = inputValue; // If the type is task or text, use the input value directly
      } else {
        updatedComment = `${commentValue.split("|&|")[0]}|&|${inputValue}`;
      }

      const storedToken = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/api/v1/comments/${id}`, {
        method: "PUT", // PUT method to update the comment
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          type_of: type,
          comment: updatedComment,
        }),
      });

      if (response.ok) {
        console.log("Comment updated successfully");
        // Reset states after successful submission
        setCommentValue(null);
        setInputValue("");
        getComments(); // Fetch the updated comments
        setEditMode(false); // Exit edit mode
        setEditId(null); // Reset the edit ID
        setIsLoading(false);
      } else {
        console.error("Failed to update comment", await response.text());
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    } 
  };


  const handlecopycomment = (text, commentId) => {
    // setCopyComment(false);
    setTextCopyId(commentId);
    navigator.clipboard.writeText(text);
    setIsCopiedText(true);

    // Create a temporary element and append a text node with the selected text
    const tempElement = document.createElement("div");
    const textNode = document.createTextNode(text);
    tempElement.appendChild(textNode);

    // Create a range for the selected text
    const range = document.createRange();
    range.selectNodeContents(tempElement);

    // Remove existing selections
    window.getSelection().removeAllRanges();

    // Add the new range to the selection
    window.getSelection().addRange(range);
  };
  const handleTextClick = () => {
    // Reset the clicked comment immediately when clicking on the selected text
    setTextCopyId(null);
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
setIsLoading(true)
    try {
      const storedToken = localStorage.getItem("token");
      let bodyData;
      let title = event.target.title.value;
      const currentDate = new Date(); // current date
      const sevenDays = new Date(currentDate);
      sevenDays.setDate(currentDate.getDate() + 7);
      const formattedDate = sevenDays.toISOString().split("T")[0];
      // Check if the user has uploaded an image or video
      if (file) {
        const imgVideoUrl = await uploadFileToS3(file);
        const fileType = file.type.startsWith("image") ? "image" : "video";
        bodyData = {
          post_id: postid,
          user_id: userId,
          type_of: `task_${fileType}`, // Set type_of to task_image for images and task_video for videos
          comment: `${imgVideoUrl}|&|${title || ""}`,
          "due_date": formattedDate
        };
      } else if (thumbnail) {
        const imgVideoUrl = await uploadFileToS3(thumbnail);
        bodyData = {
          post_id: postid,
          user_id: userId,
          type_of: "task_video", // Set type_of to task_video for videos
          comment: `${imgVideoUrl}|&|${title || ""}`,
          status: "still_working",
          "due_date": formattedDate
        };
      } else {
        // Default case: text only
        bodyData = {
          post_id: postid,
          user_id: userId,
          type_of: "task", // Set type_of to task for text comments
          comment: title,
          status: "still_working",
          "due_date": formattedDate
        };
      }

      const response = await fetch(`${API_BASE_URL}/api/v1/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("API Response Data comments:", responseData);
        getComments();
        setFile(null);
        setThumbnail(null);
        setCommentText("");
        setIsLoading(false)
      } else {
        console.error(
          "Failed to add task:",
          response.status,
          response.statusText
        );

        const errorResponse = await response.json().catch(() => ({}));
        console.error("Error Response Data:", errorResponse);
      }
    } catch (error) {
      console.error("Error submitting task:", error);
    }
  };
  const fetchRecords = async () => {
    try {
      setLoader(true);
      let token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const apiUrl = `${API_BASE_URL}/api/v1/comments?post_id=${postid}`;
      const fullUrl = `${apiUrl}&per_page=10&page=${currentPage}`;
      const response = await fetch(fullUrl, {
        method: "GET",
        headers,
      });
      if (response.ok) {
        const responseData = await response.json();
        console.log("API response:", responseData.data.pagination);

        // If it's not the first page, concatenate comments
        setComments1((prevComments) => [
          ...prevComments,
          ...responseData.data.comments,
        ]);

        setLoader(false);
        setHasMore(
          responseData?.data?.pagination?.current_page <=
          responseData?.data?.pagination?.last_page
        );
        setCurrentPage(responseData?.data?.pagination?.current_page + 1);
      } else {
        throw new Error(`API request failed with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error in API call:", error.message);
      throw error;
    }
  };
  useEffect(() => {
    getComments();
  }, [postid]);

  const handleImageClick = (imageUrl) => {
    console.log("image", imageUrl);

    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <span className="d-block todos-home pt-2">
        <span onClick={() => navigate("/posts/new")}>
          Home {`>`}
        </span>

        <Link to="/main-todo">
          <span className="todos-home">To-do {`>`}</span>
        </Link>
        <Link
          to={`/todo-list/${comments?.post?.id}?status=still_working`}
          className="todos-home ps-1"
        >
          {comments?.post?.notes}
        </Link>
      </span>

      <div className="pt-1 gigclass">
        Task Id: #{postid}{" "}
        <span className="px-2">{comments?.post?.status}</span>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className=" row flex-row">
              <div className="col-md-10 col-10 pt-3 pe-0 flex-wrap">
                <div>
                  {comments?.post?.media_type === "text" ? (
                    <></>
                  ) : comments?.post?.media_type === "image" ? (
                    <>
                      <img
                        onClick={() => handleImageClick(comments?.post?.video_url)}
                        src={comments?.post?.video_url}
                        alt="Task Images"
                        className="img-fluid"
                      />
                      <ImageModal isOpen={isModalOpen} imageSrc={selectedImage} onClose={closeModal} />
                    </>
                  ) : comments?.post?.media_type === "video" ? (
                    <video
                      // key={comments.post.id}
                      controls
                      width="240px"
                      height="320px"
                      className="rounded video-size"
                      id={comments?.post?.id}
                      onPlay={handlePlay}
                      onClick={handlePlay}
                    >
                      <source
                        src={comments?.post?.video_url}
                        type="video/mp4"
                        onClick={() => playVideo(comments?.post?.id)}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <></>
                  )}
                </div>
                <span className="post-views ps-2">2 views 21 days ago</span>
              </div>
              <div className="col-md-2 col-2 text-center my-auto ">
                <div className="d-flex flex-column">
                  <span className="">
                    <img
                      src="https://yesdone.com/build/assets/Doneapp-page-15-5e597573.png"
                      alt="Doneapp-page-15"
                      className="img-responsive post-icons "
                    />
                  </span>
                  <p className="mb-0 py-2">{/* {likes} */}</p>
                  <span className="">
                    <img
                      src="https://yesdone.com/build/assets/Doneapp-page-16-308861db.png"
                      alt="Doneapp-page-15"
                      className="img-responsive post-icons "
                    />
                  </span>
                  <p className="mb-0 py-2">{/* {comments} */}0</p>
                  <span className="">
                    <img
                      src="https://yesdone.com/build/assets/Doneapp-page-17-6a74558d.png"
                      alt="Doneapp-page-15"
                      className="img-responsive post-icons"
                    />
                  </span>
                  <p className="mb-0 py-2">{/* {saves} */}</p>
                  <span className="">
                    <img
                      src="https://yesdone.com/build/assets/Doneapp-page-18-60da9c9e.png"
                      alt="Doneapp-page-15"
                      className="img-responsive post-icons"
                    />
                  </span>
                  <p className="mb-0 py-2 ">1677</p>
                  <span className="">
                    <img
                      src="https://yesdone.com/build/assets/Doneapp-page-19-19dace4b.png"
                      alt="Doneapp-page-15"
                      className="img-responsive post-icons "
                      // data-status="still_working"
                      data-bs-toggle="modal"
                      data-bs-target="#post-update-modal"
                    />
                  </span>
                  <span className="py-3">
                    <img
                      src="https://dtzxzoe2ldz0i.cloudfront.net/job/Delete-icon.png"
                      alt="delete"
                      className="cursor-point post-icons"
                    />
                  </span>
                  {/* <input type="hidden" /> */}
                </div>
              </div>
            </div>
            {/* <div>
              <h5 className="">To Do List</h5>
              <span className="form-check mb-2">
                <input
                  className="form-check-input todo-border"
                  type="checkbox"
                  id="to_do"
                  name="to_do"
                  value="1"
                  form="comment-create-form"
                />
                <label className="form-check-label todo-label">1.</label>
              </span>
              <span className="form-check mb-2">
                <input
                  className="form-check-input todo-border"
                  type="checkbox"
                  id="to_do"
                  name="to_do"
                  value="1"
                  form="comment-create-form"
                />
                <label className="form-check-label todo-label">2.</label>
              </span>
              <span className="form-check mb-2">
                <input
                  className="form-check-input todo-border"
                  type="checkbox"
                  id="to_do"
                  name="to_do"
                  value="1"
                  form="comment-create-form"
                />
                <label className="form-check-label todo-label">3.</label>
              </span>
              <span className="form-check mb-2">
                <input
                  className="form-check-input todo-border"
                  type="checkbox"
                  id="to_do"
                  name="to_do"
                  value="1"
                  form="comment-create-form"
                />
                <label className="form-check-label todo-label">4.</label>
              </span>
              <span className="form-check mb-2">
                <input
                  className="form-check-input todo-border"
                  type="checkbox"
                  id="to_do"
                  name="to_do"
                  value="1"
                  form="comment-create-form"
                />
                <label className="form-check-label todo-label">5.</label>
              </span>
            </div> */}
            {/* <div className=" d-flex   align-items-center comment-box comments-border my-3 ">
              <div>
                <img
                  src="https://yesdone.com/build/assets/Doneapp-page-21-3931b488.png"
                  alt="Doneapp-page-20"
                  className="img-responsive comments-img "
                />
                <i className="fa-solid fa-circle-plus text-danger plus-icon"></i>
              </div>
              <div>
                <span className="comment-name fw-bold ps-4">Max Lun</span>
                <span className="ps-1 ">
                  <i className="bi bi-check-circle-fill icon-green"></i>
                </span>
                <br />
                <span className="taskdone  ps-4">425 Tasks Done</span>
                <br />
                <span className="d-flex align-items-center">
                  <span className="fw-bold ps-2 me-2">
                    3{" "}
                    <img
                      src="https://yesdone.com/build/assets/gb-bb8e7a15.png"
                      alt="Doneapp-page used-45"
                      className="img-responsive"
                    />
                  </span>
                  <ul className="d-flex m-0 ps-1 star-gold  list-unstyled custom-list">
                    <li>
                      <i className="bi bi-star-fill "></i>
                    </li>
                    <li className="ps-1">
                      <i className="bi bi-star-fill"></i>
                    </li>
                    <li className="ps-1">
                      <i className="bi bi-star-fill"></i>
                    </li>
                    <li className="ps-1">
                      <i className="bi bi-star-fill"></i>
                    </li>
                    <li className="ps-1">
                      <i className="bi bi-star-fill"></i>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="col-xl-5 d-flex ms-auto pe-2">
                <button className="btn rounded-2 fs-btn-accept fs-md-5 text-white px-4">
                  $150 - Accept
                </button>
              </div>
            </div> */}
          </div>
          <div className="col-md-8 left-border">
            <form
              id="todo-comment-create-form"
              onSubmit={(e) => handleFormSubmit(e)}
            >
              <div className="form-control d-flex align-items-end border-0 position-relative mb-5">
                <textarea
                  value={commentText}
                  onChange={handleTextareaChange}
                  required
                  name="title"
                  className="form-control border px-2  shadow-none"
                  rows="4"
                  placeholder=""
                ></textarea>

                <div className="absoluteposition mt-2 me-2 pt-5 pe-4 pb-2">
                  {(thumbnail || file) && (
                    <ImagePreview
                      file={thumbnail || file}
                      onDelete={handleFileDelete}
                    />
                  )}
                </div>
                <div className="position-absolute end-0 mt-2 me-2 pt-5 pe-4 pb-2">
                  {/* Wrap the image inside a label element */}
                  <label htmlFor="imgFileUpload" className="custom-file-upload">
                    <img
                      src="https://yesdone.com/build/assets/Doneapp-page-11-e43f6bb5.png"
                      alt=""
                      width="40"
                      className="mt-2 px-2 cursor-pointer"
                      height="20"
                    // style={{ cursor: "pointer" }}
                    />
                    {/* The file input */}
                    <input
                      id="imgFileUpload"
                      type="file"
                      name="files"
                      accept="image/*, video/*"
                      onChange={handleFileChange}
                    // style={{ display: "none" }} // Hide the file input
                    />
                  </label>

                   <button
                    name="submit"
                    id="search-button"
                    type="submit"
                    className="btn btn-success fw-normal btn-sm px-4"
                  >
                    <b>Send</b>
                  </button>
                </div>
              </div>
            </form>
            {isUploading && (
                   <div className="upload-img-video">
                   <img src={uplading} alt="" className="uploadloader-img" />
                </div>
                  )}

                   {isLoading && (
                   <div className="upload-img-video">
                   <img src={uplading} alt="" className="uploadloader-img" />
                </div>
                  )}
                  
            {loader ? (
              <div className="d-flex align-items-center justify-content-center my-2">
                {/* <img src={LoadingImg} alt="" width="50px" /> */}
                <ChatWindowStatic />
              </div>
            ) : (
              <>

                <div className="ps-4">{comments?.post?.notes}</div>
                <InfiniteScroll
                  dataLength={comments1.length}
                  next={() => fetchRecords()}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDiv"
                >
                  <div
                    className="scrollableChat scrollable-overflow"
                    // style={{ overflowX: "hidden" }}
                    id="scrollableDiv"
                  >
                    {comments1.map((comment, index) => (
                      <React.Fragment key={index}>
                        <>
                          {" "}
                          <div
                            className={`d-flex justify-content-${Number(comment.creator.id) === Number(userId)
                                ? "end"
                                : "start"
                              } `}
                          >
                            <span
                              className={`${(comment.type_of === "task" ||
                                  comment.type_of === "text") &&
                                (Number(comment.creator.id) === Number(userId)
                                  ? "comment-text"
                                  : "comment-textRight")
                                }
                      ${editMode && Number(comment.id) === Number(editId)
                                  ? "bg-white comment1-text"
                                  : "none"
                                } 
                       mt-4 rounded p-2 moving-aud`}
                            >
                              {(comment.type_of === "text" ||
                                comment.type_of === "task") && (
                                  <>
                                    {editMode &&
                                      Number(comment.id) === Number(editId) ? (
                                      <>
                                        {/* Render your edit content (e.g., {hello}) when editMode is true */}
                                        <form
                                          className="d-flex  align-items-center mb-2 mt-1"
                                          onSubmit={(e) =>
                                            handleSubmit(
                                              e,
                                              comment.id,
                                              comment.type_of
                                            )
                                          }
                                        >
                                          <input
                                            type="hidden"
                                            name="id"
                                            value={inputValue}
                                          />
                                          <textarea
                                            className="form-control custom-form-edit shadow-none"
                                            name="comment"
                                            rows="2"
                                            value={inputValue}
                                            onChange={(e) =>
                                              handleChange(e.target.value)
                                            }
                                          ></textarea>
                                          <button
                                            // type="submit"
                                            className="btn btn-success btn-sm mx-2 py-1 text-white"
                                          >
                                            <span className="textwhite">
                                              <i
                                                className="fa fa-check"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </button>
                                          <span
                                            onClick={() => {
                                              setEditMode(false);
                                              setEditId(null);
                                            }}
                                          >
                                            <i className="bi bi-x-circle-fill text-success fw-bolder fs-3"></i>
                                          </span>
                                        </form>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor:
                                              textcopyId === comment.id &&
                                                isCopiedtext
                                                ? "#1f02ed"
                                                : null,
                                          }}
                                          onClick={handleTextClick}
                                        >
                                          {comment.comment}
                                        </span>
                                      </>
                                    )}
                                  </>
                                )}
                              {(comment.type_of === "image" ||
                                comment.type_of === "task_image") && (
                                  <>
                                    {editMode &&
                                      Number(comment.id) === Number(editId) ? (
                                      <form
                                        className="d-flex  align-items-center mb-2 mt-1"
                                        onSubmit={(e) =>
                                          handleSubmit(e, comment.id, comment.type_of)
                                        }
                                      >
                                        <input
                                          type="hidden"
                                          name="id"
                                          value={inputValue}
                                        />
                                        <textarea
                                          className="form-control custom-form-edit shadow-none"
                                          name="comment"
                                          rows="2"
                                          value={inputValue}
                                          onChange={(e) =>
                                            handleChange(e.target.value)
                                          }
                                        ></textarea>
                                        <button
                                          // type="submit"
                                          className="btn btn-success btn-sm mx-2 py-1 text-white"
                                        >
                                          <span className="textwhite">
                                            <i
                                              className="fa fa-check"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </button>
                                        <span
                                          onClick={() => {
                                            setEditMode(false);
                                            setEditId(null);
                                          }}
                                        >
                                          <i className="bi bi-x-circle-fill text-success fw-bolder fs-3"></i>
                                        </span>
                                      </form>
                                    ) : comment.comment.includes("|&|") ? (
                                      comment.comment
                                        .split("|&|")
                                        .map((segment, index) => (
                                          <React.Fragment key={index}>
                                            {index === 0 ? (
                                              <img
                                                key={index}
                                                src={segment}
                                                alt={`Images/Video ${index + 1}`}
                                                width="400px"
                                                height="400px"
                                                onClick={() => handleImageClick(segment)}
                                                className="rounded pb-2 imagewidth-comments"
                                              />
                                            ) : (
                                              <div className={`${Number(comment.creator.id) === Number(userId) ? " text-comment comment-text" : " comment-textRight "} rounded p-2`}
                                              >{segment}</div>
                                            )}
                                          </React.Fragment>
                                        ))
                                    ) : (
                                      <img
                                        src={comment.comment}
                                        alt={`Images/Video`}
                                        width="400px"
                                        height="400px"
                                        onClick={() => handleImageClick(comment.comment)}
                                        className="rounded pb-2"
                                      />
                                    )}
                                  </>
                                )}

                              {(comment.type_of === "video" ||
                                comment.type_of === "task_video" ||
                                comment.type_of === "youtube") && (
                                  <>
                                    {editMode &&
                                      Number(comment.id) === Number(editId) ? (
                                      <form
                                        className="d-flex  align-items-center mb-2 mt-1"
                                        onSubmit={(e) =>
                                          handleSubmit(e, comment.id, comment.type_of)
                                        }
                                      >
                                        <input
                                          type="hidden"
                                          name="id"
                                          value={inputValue}
                                        />
                                        <textarea
                                          className="form-control custom-form-edit shadow-none"
                                          name="comment"
                                          rows="2"
                                          value={inputValue}
                                          onChange={(e) =>
                                            handleChange(e.target.value)
                                          }
                                        ></textarea>
                                        <button
                                          // type="submit"
                                          className="btn btn-success btn-sm mx-2 py-1 text-white"
                                        >
                                          <span className="textwhite">
                                            <i
                                              className="fa fa-check"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </button>
                                        <span
                                          onClick={() => {
                                            setEditMode(false);
                                            setEditId(null);
                                          }}
                                        >
                                          <i className="bi bi-x-circle-fill text-success fw-bolder fs-3"></i>
                                        </span>
                                      </form>
                                    ) : (
                                      <>
                                        {comment.comment.includes("|&|") ? (
                                          comment.comment
                                            .split("|&|")
                                            .map((segment, index) => (
                                              <React.Fragment key={index}>
                                                {index === 0 ? (
                                                  <video
                                                    key={index}
                                                    controls
                                                    width="400px"
                                                    height="400px"
                                                    className="rounded object-fit-cover"
                                                    id={comment.id}
                                                    onPlay={handlePlay}
                                                    onClick={handlePlay}
                                                  >
                                                    <source
                                                      src={segment}
                                                      type="video/mp4"
                                                      onClick={() =>
                                                        playVideo(comment.id)
                                                      }
                                                    />
                                                    Your browser does not support the
                                                    video tag.
                                                  </video>
                                                ) : (
                                                  <div
                                                    className={`text-break${Number(comment.creator.id) ===
                                                        Number(userId)
                                                        ? " text-comment comment-text"
                                                        : " comment-textRight "
                                                      } rounded p-2`}
                                                  >
                                                    {segment}
                                                  </div>
                                                )}
                                              </React.Fragment>
                                            ))
                                        ) : (
                                          <video
                                            controls
                                            width="400px"
                                            height="400px"
                                            className="rounded"
                                            id={comment.id}
                                            onClick={handlePlay}
                                            onPlay={handlePlay}
                                          >
                                            <source
                                              src={comment.comment}
                                              type="video/mp4"
                                              onClick={() => playVideo(comment.id)}
                                            />
                                            Your browser does not support the video
                                            tag.
                                          </video>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              {comment.type_of === "audio" && (
                                <>
                                  {editMode &&
                                    Number(comment.id) === Number(editId) ? (
                                    <form
                                      className="d-flex  align-items-center mb-2 mt-1"
                                      onSubmit={(e) =>
                                        handleSubmit(e, comment.id, comment.type_of)
                                      }
                                    >
                                      <input
                                        type="hidden"
                                        name="id"
                                        value={inputValue}
                                      />
                                      <textarea
                                        className="form-control custom-form-edit shadow-none"
                                        name="comment"
                                        rows="2"
                                        value={inputValue}
                                        onChange={(e) =>
                                          handleChange(e.target.value)
                                        }
                                      ></textarea>
                                      <button
                                        // type="submit"
                                        className="btn btn-success btn-sm mx-2 py-1 text-white"
                                      >
                                        <span className="textwhite">
                                          <i
                                            className="fa fa-check"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </button>
                                      <span
                                        onClick={() => {
                                          setEditMode(false);
                                          setEditId(null);
                                        }}
                                      >
                                        <i className="bi bi-x-circle-fill text-success fw-bolder fs-3"></i>
                                      </span>
                                    </form>
                                  ) : (
                                    <>
                                      <audio
                                        id={`audio-element${comment.id}`}
                                        controls
                                      >
                                        <source
                                          src={comment.comment}
                                          type="audio/mp3"
                                        />
                                        Your browser does not support the audio tag.
                                      </audio>
                                    </>
                                  )}
                                </>
                              )}
                            </span>

                            <div
                              className={`${editMode && comment.id === editId
                                  ? "d-none"
                                  : "dropdown"
                                }`}
                            >
                              {Number(comment.creator.id) === Number(userId) ? (
                                <>
                                  <span
                                    className={`btn text-dark bg-white`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <i
                                      className="fa fa-ellipsis-v pt-4"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <ul
                                    className="dropdown-menu"
                                    data-popper-placement="bottom-end"
                                  >
                                    <li>
                                      <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() =>
                                          handlecopycomment(
                                            comment.comment,
                                            comment.id
                                          )
                                        }
                                      >
                                        Copy Text
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() =>
                                          handleOpenEdit(
                                            comment.id,
                                            comment.comment,
                                            comment.type_of
                                          )
                                        }
                                      >
                                        Edit
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => handleOpenModal(comment.id)}
                                      >
                                        Delete
                                      </span>
                                    </li>
                                  </ul>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {editMode &&
                            Number(comment.id) === Number(editId) ? null : (
                            <div
                              className={`text-${Number(comment.creator.id) === Number(userId)
                                  ? "end"
                                  : "start"
                                } py-2`}
                            >
                              {comment.creator.profile_image ? (
                                <span className="img-fluid text-muted custom-fontsize adjust-name">
                                  <img
                                    src={comment.creator.profile_image}
                                    className="rounded-circle"
                                    alt="profile_logo"
                                    width="25px"
                                    height="25px"
                                  />{" "}
                                  by {comment.creator.first_name}{" "}
                                  {comment.creator.last_name}
                                </span>
                              ) : (
                                <span>
                                  {comment.creator.first_name && (
                                    <>
                                      <span className="codecolor">
                                        {comment.creator.first_name
                                          .charAt(0)
                                          .toUpperCase()}
                                      </span>{" "}
                                    </>
                                  )}
                                  by <span>{comment.creator.first_name}</span>{" "}
                                  {comment.creator.last_name}
                                </span>
                              )}
                            </div>
                          )}
                        </>
                      </React.Fragment>
                    ))}
                    <Modal
                      show={showModal}
                      onHide={handleCloseModal}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Modal.Header
                        //  closeButton
                        className="fs-1 fw-bold text-center border-0 mx-auto"
                      >
                        <i
                          className="bi bi-exclamation-circle text-center text-danger modal-icon"
                        // style={{
                        //   fontSize: "85px",
                        //   color: "#f8bb86 !important",
                        // }}
                        ></i>
                      </Modal.Header>
                      <Modal.Body className="">
                        Are you sure you want delete this ?
                      </Modal.Body>
                      <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
                        <Button variant="primary" onClick={handleDeleteMini}>
                          Yes
                        </Button>
                        <Button variant="primary" onClick={handleCloseModal}>
                          No
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <ImageModal isOpen={isModalOpen} imageSrc={selectedImage} onClose={closeModal} />
                  </div>
                </InfiniteScroll>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;