import React, { useState } from "react";
import Tippy from "@tippyjs/react";

const ProfilePage = () => {
  const [statesAry, setStatesAry] = useState([
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ]);

  const [imageFile, setImageFile] = useState(null);
  const defaultProfileDetails = {
    profile_image: "",
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    date_of_birth: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
  };

  const storedProfileDetails = JSON.parse(
    localStorage.getItem("profile_Details")
  );
  const [profileDetails, setProfileDetails] = useState(
    storedProfileDetails || defaultProfileDetails
  );
  const storedToken = localStorage.getItem("token");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleUpdateProfile = () => {
    // Prepare the request body
    const formData = new FormData();

    // Add the updated fields to formData
    if (profileDetails.first_name !== storedProfileDetails.first_name) {
      formData.append("first_name", profileDetails.first_name);
    }
    if (profileDetails.last_name !== storedProfileDetails.last_name) {
      formData.append("last_name", profileDetails.last_name);
    }
    if (profileDetails.profile_image !== storedProfileDetails.profile_image) {
      formData.append("profile_image", profileDetails.profile_image);
    }
    if (profileDetails.city !== storedProfileDetails.city) {
      formData.append("city", profileDetails.city);
    }
    if (profileDetails.state !== storedProfileDetails.state) {
      formData.append("state", profileDetails.state);
    }
    if (profileDetails.address !== storedProfileDetails.address) {
      formData.append("address", profileDetails.address);
    }
    if (profileDetails.country !== storedProfileDetails.country) {
      formData.append("country", profileDetails.country);
    }
    if (profileDetails.postal_code !== storedProfileDetails.postal_code) {
      formData.append("postal_code", profileDetails.postal_code);
    }
    if (profileDetails.gender !== storedProfileDetails.gender) {
      formData.append("gender", profileDetails.gender);
    }
    if (profileDetails.date_of_birth !== storedProfileDetails.date_of_birth) {
      formData.append("date_of_birth", profileDetails.date_of_birth);
    }
    if (profileDetails.phone !== storedProfileDetails.phone) {
      formData.append("phone", profileDetails.phone);
    }

    // Make the API call to update the profile
    fetch("https://beta.yesdone.com/api/v1/profile-update", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${storedToken}`, // Add your access token here
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Profile updated successfully:", data);
        // Update the UI only after the API call succeeds
        setProfileDetails(data);
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Update the profileDetails state with the changed value
    setProfileDetails((prevProfileDetails) => ({
      ...prevProfileDetails,
      [name]: value,
    }));
  };
  

  return (
    <div className="p-3 row">
      <div className="card col-12 p-2">
        <h2 className="text-center pt-2">Profile</h2>
        <form>
        <div className="d-flex justify-content-center align-items-center">
  <div className="rounded-circle border rounded-profile">
    <img
      src={profileDetails.profile_image}
      className="w-100 h-100 d-flex align-items-center justify-content-center"
      alt="profile_logo"
    />
  </div>
</div>

          <Tippy content="Edit">
            <span className="d-flex justify-content-center pt-2">
              <span
                className="cursor-point hide-on-small-devices edit-profile  border"
                onClick={() => document.getElementById("profileImage").click()}
              >
                <img
                  src="https://yesdone.com/build/assets/pencil-bc3d8d52.png"
                  alt=""
                  className="pencilbox"
                  width="16px"
                  height="16px"
                />
                <input
                  type="file"
                  id="profileImage"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <span className="ps-2 ">Edit Profile</span>
              </span>
            </span>
          </Tippy>
          <span className="d-flex justify-content-center py-2">
            <h5>{profileDetails.email}</h5>
          </span>

          <div className="row mb-md-3 mb-2 border-top pt-3 d-flex justify-content-center">
            <div className="col-md-5 col-12">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                className="form-control shadow-none"
                placeholder="First Name"
                name="first_name"
                value={profileDetails.first_name}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-5 col-12 pt-md-0 pt-2">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className="form-control shadow-none"
                placeholder="Last Name"
                name="last_name"
                value={profileDetails.last_name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-2 mb-md-3  d-flex justify-content-center">
            <div className="col-md-5 col-12">
              <label htmlFor="gender">Gender</label>
              <select
                className="form-select shadow-none"
                name="gender"
                value={profileDetails.gender}
                onChange={handleInputChange}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="col-md-5 col-12 pt-md-0 pt-2">
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <input
                type="date"
                className="form-control shadow-none"
                placeholder="Date of Birth"
                name="date_of_birth"
                value={profileDetails.date_of_birth}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-2 mb-md-3 d-flex justify-content-center">
            <div className="col-md-5 col-12">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                className="form-control shadow-none"
                placeholder="Address"
                name="address"
                value={profileDetails.address}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-5 col-12 pt-md-0 pt-2">
              <label htmlFor="city">City</label>
              <input
                type="text"
                className="form-control shadow-none"
                placeholder="City"
                name="city"
                value={profileDetails.city}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-2 mb-md-3 d-flex justify-content-center">
            <div className="col-md-5 col-12">
              <label htmlFor="address">Country</label>
              <input
                type="text"
                className="form-control shadow-none"
                placeholder="Address"
                name="address"
                value={profileDetails.country}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-5 col-12 pt-md-0 pt-2">
              <label htmlFor="city">phone</label>
              <input
                type="text"
                className="form-control shadow-none"
                placeholder="City"
                name="city"
                value={profileDetails.phone}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-2 mb-md-3  d-flex justify-content-center">
            <div className="col-md-5 col-12">
              <label htmlFor="state">State</label>
              <select
                className="form-select shadow-none"
                name="state"
                value={profileDetails.state}
                onChange={handleInputChange}
              >
                <option value="">Select a state</option>
                {statesAry.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-5 col-12 pt-md-0 pt-2">
              <label htmlFor="zipCode">Zip Code</label>
              <input
                type="text"
                className="form-control shadow-none"
                placeholder="Zip Code"
                name="postal_code"
                value={profileDetails.postal_code}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <span className="d-flex justify-content-center pb-2">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleUpdateProfile}
            >
              Update
            </button>
          </span>
        </form>
      </div>
    </div>
  );
};

export default ProfilePage;
