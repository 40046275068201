import React, { useState, useEffect } from "react";
import { Modal, Accordion } from "react-bootstrap";
import "./NameModal.css";
import API_BASE_URL from "../../Config";
import axios from 'axios';
import LoadingImg from "../../images/loading.webp";

const NameModal = ({ handleCloseButton, show, nameValue, idValue }) => {
  const [options, setOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSectionName, setSelectedSectionName] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [loading, setLoading] = useState(false);

  const apiUrl = `${API_BASE_URL}/api/v1/user-data`;

  const fetchUserData = async () => {
    const token = localStorage.getItem("token"); // Get the token from local storage
  
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}` // Set the authorization header
        }
      });
  
      // Handle the response data
      console.log('User Data:', response.data);
      
      // Combine countries and filtered departments into a single array
      const allowedDepartmentIds = [1, 3, 15]; // Only include these department IDs
      const combinedOptions = [
        ...response.data.countries.map(country => ({
          type: 'country',
          id: country.country, // Store country code
          name: country.section_name,
          section_name: country.section_name // Store section name for easy access
        })),
        ...response.data.departments
          .filter(department => allowedDepartmentIds.includes(department.id)) // Filter departments
          .map(department => ({
            type: 'department',
            id: department.id, // Store department ID
            name: department.alias_name
          }))
      ];
  
      setOptions(combinedOptions); // Set the combined options
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle errors as necessary
    }
  };
  

  // Fetch user data when the modal is shown
  useEffect(() => {
    if (show) {
      fetchUserData();
    }
  }, [show]);

  // Handle radio button selection
  const handleSelection = (option) => {
    if (option.type === 'country') {
      setSelectedCountry(option.id); // Set country code
      setSelectedSectionName(option.section_name); // Set section name from the selected country
      setSelectedDepartment(null); // Reset department selection
      console.log('Selected Country:', option.id, option.section_name); // Log selected country
    } else if (option.type === 'department') {
      setSelectedDepartment(option.id); // Set department ID
      setSelectedCountry(null); // Reset country selection
      setSelectedSectionName(null); // Reset section name when a department is selected
      console.log('Selected Department:', option.id); // Log selected department
    }
  };

  // Handle submit action
  const handleSubmit = async () => {
    setLoading(true);
    let requestBody = {
      user_id: idValue, // Use the provided user ID
    };

    // Check if a country or department is selected and update requestBody accordingly
    if (selectedCountry) {
      requestBody.country = selectedCountry; 
      requestBody.section_name = selectedSectionName; // Add selected section name
    } else if (selectedDepartment) {
      requestBody.department_id = selectedDepartment; // Add selected department
    }

    try {
      const token = localStorage.getItem("token"); // Get the token from local storage
      const response = await axios.put(`${API_BASE_URL}/api/v1/update-user`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
          'Content-Type': 'application/json' // Ensure the content type is JSON
        }
      });
      setLoading(false);
      handleCloseButton();
      window.location.reload();
      console.log('Update Response:', response.data); // Log the response from the server

    } catch (error) {
      console.error('Error updating user data:', error);
      // Handle error accordingly (e.g., show error message)
      setLoading(false); // Reset loading state on error
    }
  };

  return (
    <Modal show={show} onHide={handleCloseButton}>
      <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="mt-2">
        <div className="text-center fw-semibold name-value">
          <p>{nameValue}</p>
        </div>

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Categories</Accordion.Header>
            <Accordion.Body>
              {options.map((option, index) => (
                <div
                  className="form-check d-flex align-items-end border-bottom border-2 ps-5 pb-3 pt-1"
                  key={index}
                >
                  <input
                    className="form-check-input shadow-none"
                    type="radio"
                    name="singleOptionRadio"
                    id={`singleOptionRadio${index + 1}`}
                    onChange={() => handleSelection(option)} // Handle selection
                    checked={
                      (option.type === 'country' && option.id === selectedCountry) ||
                      (option.type === 'department' && option.id === selectedDepartment)
                    } // Manage checked state
                  />
                  <label
                    className="form-check-label ps-3"
                    htmlFor={`singleOptionRadio${index + 1}`}
                  >
                    {option.name} {/* Display either country or department name */}
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="text-center mx-auto name-submit mt-4 mb-3 w-50 rounded-pill py-1">
          {loading ? (
            <button className="btn border-0 shadow-none text-white" disabled>
              <img
                src={LoadingImg}
                width="20px"
                height="20px"
                alt="Loading"
              />
            </button>
          ) : (
            <button className="btn border-0 shadow-none text-white" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NameModal;
