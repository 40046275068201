// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-radio .form-check-input{
    border: 2px solid #009345 !important;
    font-size: 26px ;
}
.btn-radio .form-check-label{
    font-size: 18px ;
}
.name-submit{
    background-color: #009345;
}
.name-value p{
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/Components/todos/NameModal.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,eAAe;AACnB","sourcesContent":[".btn-radio .form-check-input{\n    border: 2px solid #009345 !important;\n    font-size: 26px ;\n}\n.btn-radio .form-check-label{\n    font-size: 18px ;\n}\n.name-submit{\n    background-color: #009345;\n}\n.name-value p{\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
