import s3 from "./aws-sdk";
import imageCompression from "browser-image-compression";
import { format, parse } from 'date-fns';
export function genNewSearchParamString(searchParams, key, value) {
  const sp = new URLSearchParams(searchParams);
  console.log("search params", sp);
  if (key === "user_id" || key === "group_id") {
    sp.delete("user_id");
    sp.delete("group_id");
    sp.set("status", "still_working");
  }
  if (value === null) {
    sp.delete(key);
  } else {
    sp.set(key, value);
  }
  return `?${sp.toString()}`;
}

export const compressImage = async (image) => {
  const options = {
    maxSizeMB: 2,
    useWebWorker: true,
    maxWidthOrHeight: 1920,
  };

  try {
    const compressed = await imageCompression(image, options);

    // Convert the compressed image to a data URL
    const compressedDataURL = URL.createObjectURL(compressed);

    // Convert the data URL to a Blob
    const blob = await fetch(compressedDataURL).then((res) => res.blob());

    // Create a new File object with the Blob and specify the desired file name and type
    const compressedFile = new File([blob], 'compressed.jpg', { type: 'image/jpeg' });

    console.log(`${compressedFile.size / 1024 / 1024} MB`);
    return compressedFile;
  } catch (error) {
    console.error("Image compression failed:", error);
    return image; // Return the original image in case of an error
  }
};


export const videoThumbnail = async (
  video /* (url) => string | (file) => Blob */
) => {
  const videoURL = video instanceof Blob ? URL.createObjectURL(video) : video;

  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    canvas.hidden = true;
    const context = canvas.getContext("2d");

    const videoElement = document.createElement("video");
    videoElement.hidden = true;
    videoElement.src = videoURL;
    videoElement.muted = true;
    videoElement.autoplay = true;

    videoElement.onloadeddata = () => {
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        videoElement.pause();
        videoElement.src = "";
        videoElement.remove();
        canvas.remove();
        resolve(blob);
      });
    };
    videoElement.crossOrigin = "anonymous";
    videoElement.onerror = () => resolve(null);
  });
};

export const uploadFileToS3 = async (file) => {
  const chunks = file?.name?.split(".");
  const ext = chunks && chunks.length ? chunks[chunks.length - 1] : ''; // Check if chunks is defined and has length
  const name = `file_${new Date().toISOString().replace(/[-:]/g, "")}.${ext}`;
  console.log(name);
  const params = {
    Bucket: process.env.S3_BUCKET_NAME || "doneappai-beta",
    Key: name,
    Body: file,
  };

  try {
    await s3.upload(params).promise();
    return `https://d2otsz8k52dp9s.cloudfront.net/${name}`;
  } catch (error) {
    console.error("=> Error uploading file:", error);
    return "";
  }
};


export const getTodoReqParams = (
  title,
  img_video_url,
  thumbnail_url,
  user_group_id,
  is_user = true,
  urgent,
  initialMediaType,
  formattedSelectedDate 
) => {
  // console.log("response values", title, img_video_url, thumbnail_url, user_group_id, is_user);
  const currentDate = new Date(); // current date
  const sevenDays = new Date(currentDate);
  sevenDays.setDate(currentDate.getDate() + 7);
  const formattedDate = formattedSelectedDate ||  sevenDays.toISOString().split("T")[0];
  let mediaType = initialMediaType === "video" ? "video" : // Keep it as video if initial was video
                  img_video_url === "" || img_video_url === null || img_video_url === "text" ? "text" : // Check for text
                  "image"; // Otherwise, assume it's an image

  // Check if video_url ends with ".mp4" or any other video-related format
  if (img_video_url && img_video_url.endsWith(".mp4") ) {
    mediaType = "video";
  }

  const todoReqParams = JSON.stringify({
    tags: [],
    links: [],
    miles: "",
    zipcode: "",
    services: [],
    latitude: "",
    quantity: "0",
    min_amount: 0,
    max_amount: 0,
    longitude: "",
    description: "",
    category_id: "",
    free_boost: false,
    auto_renew: false,
    monthly_fee: false,
    extremely_urgent: urgent,
    project_type: "to-do",
    commission_amount: "0",
    commission_type: "Increase",
    video_restriction: "Nationwide",
    notes: title, // User input - title
    video_url: img_video_url, // video url
    users: is_user ? [user_group_id] : [],
    groups: is_user ? [] : [user_group_id],
    video_type: 0, // "landscape: 0" or "portrait: 1"
    video_image: thumbnail_url, // video url thumbnail
    commission_due_date: formattedDate, // date from 7 days "2023-11-23"
    media_type: mediaType
  });

  console.log(todoReqParams);
  return todoReqParams;
};

export const getCreateTodoReqParams = (
  title,
  img_video_url,
  thumbnail_url,
  group_id,
  user_id,
  initialMediaType
) => {
  // console.log("response values", title, img_video_url, thumbnail_url, user_group_id, is_user);
  const currentDate = new Date(); // current date
  const sevenDays = new Date(currentDate);
  sevenDays.setDate(currentDate.getDate() + 7);
  const formattedDate = sevenDays.toISOString().split("T")[0];
  let mediaType = initialMediaType === "video" ? "video" : // Keep it as video if initial was video
                  img_video_url === "" || img_video_url === null || img_video_url === "text" ? "text" : // Check for text
                  "image"; // Otherwise, assume it's an image

  // Check if video_url ends with ".mp4" or any other video-related format
  if (img_video_url && img_video_url.endsWith(".mp4") ) {
    mediaType = "video";
  }

  const todoReqParams = JSON.stringify({
    tags: [],
    links: [],
    miles: "",
    zipcode: "",
    services: [],
    latitude: "",
    quantity: "0",
    min_amount: 0,
    max_amount: 0,
    longitude: "",
    description: "",
    category_id: "",
    free_boost: false,
    auto_renew: false,
    monthly_fee: false,
    extremely_urgent: 0,
    project_type: "to-do",
    commission_amount: "0",
    commission_type: "Increase",
    video_restriction: "Nationwide",
    notes: title, // User input - title
    video_url: img_video_url, // video url
    users: user_id,
    groups: group_id,
    video_type: 0, // "landscape: 0" or "portrait: 1"
    video_image: thumbnail_url, // video url thumbnail
    commission_due_date: formattedDate, // date from 7 days "2023-11-23"
    media_type: mediaType
  });

  console.log(todoReqParams);
  return todoReqParams;
};
export const getTodoReqParams1 = (
  title,
  img_video_url,
  thumbnail_url,
  userIds,
  groupIds,
  initialMediaType
) => {
  // console.log("response values", title, img_video_url, thumbnail_url, user_group_id, is_user);
  const currentDate = new Date(); // current date
  const sevenDays = new Date(currentDate);
  sevenDays.setDate(currentDate.getDate() + 7);
  const formattedDate = sevenDays.toISOString().split("T")[0];
  let mediaType =
    initialMediaType === "video"
      ? "video" // Keep it as video if initial was video
      : img_video_url === "" ||
        img_video_url === null ||
        img_video_url === "text"
      ? "text" // Check for text
      : "image"; // Otherwise, assume it's an image

  // Check if video_url ends with ".mp4" or any other video-related format
  if (img_video_url && img_video_url.endsWith(".mp4")) {
    mediaType = "video";
  }

  const todoReqParams = JSON.stringify({
    tags: [],
    links: [],
    miles: "",
    zipcode: "",
    services: [],
    latitude: "",
    quantity: "0",
    min_amount: 0,
    max_amount: 0,
    longitude: "",
    description: "",
    category_id: "",
    free_boost: false,
    auto_renew: false,
    monthly_fee: false,
    extremely_urgent: 0,
    project_type: "to-do",
    commission_amount: "0",
    commission_type: "Increase",
    video_restriction: "Nationwide",
    notes: title, // User input - title
    video_url: img_video_url, // video url
    users: userIds,
    groups: groupIds,
    video_type: 0, // "landscape: 0" or "portrait: 1"
    video_image: thumbnail_url, // video url thumbnail
    commission_due_date: formattedDate, // date from 7 days "2023-11-23"
    media_type: mediaType,
  });

  console.log(todoReqParams);
  return todoReqParams;
};
// export const calculateTimeDifference = (timestamp) => {
//   const currentTime = new Date();
//   const updatedTime = new Date(timestamp);

//   // Adjust for IST (Indian Standard Time)
//   updatedTime.setHours(updatedTime.getHours() + 13);
//   updatedTime.setMinutes(updatedTime.getMinutes() + 30);

//   const timeDifferenceInSeconds = Math.floor(
//     (currentTime - updatedTime) / 1000
//   );

//   const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

//   if (timeDifferenceInSeconds < 60) {
//     return "just now";
//   } else if (timeDifferenceInSeconds < 3600) {
//     const minutes = Math.floor(timeDifferenceInSeconds / 60);
//     return rtf.format(-minutes, "minute");
//   } else if (timeDifferenceInSeconds < 86400) {
//     const hours = Math.floor(timeDifferenceInSeconds / 3600);
//     return rtf.format(-hours, "hour");
//   } else if (timeDifferenceInSeconds < 2592000) {
//     const days = Math.floor(timeDifferenceInSeconds / 86400);
//     return rtf.format(-days, "day");
//   } else if (timeDifferenceInSeconds < 2592000 * 2) {
//     // Less than 2 months
//     const formattedDate = updatedTime.toLocaleString("en-US", {
//       month: "short",
//       day: "numeric",
//     });
//     return rtf.format(-1, "month") + " " + formattedDate;
//   } else {
//     // More than 2 months
//     const formattedDate = updatedTime.toLocaleString("en-US", {
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//     });
//     return formattedDate;
//   }
// };
// export const calculateTimeDifference = (timestamp) => {
//   const currentTime = new Date();
//   const updatedTime = new Date(timestamp);

//   // Adjust for IST (Indian Standard Time)
//   updatedTime.setHours(updatedTime.getHours() + 13);
//   updatedTime.setMinutes(updatedTime.getMinutes() + 30);

//   const timeDifferenceInSeconds = Math.floor(
//     (currentTime - updatedTime) / 1000
//   );

//   const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

//   if (timeDifferenceInSeconds < 60) {
//     return "just now";
//   } else if (timeDifferenceInSeconds < 3600) {
//     const minutes = Math.floor(timeDifferenceInSeconds / 60);
//     return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
//   } else if (timeDifferenceInSeconds < 86400) {
//     const hours = Math.floor(timeDifferenceInSeconds / 3600);
//     return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
//   } else if (timeDifferenceInSeconds < 2592000) {
//     const days = Math.floor(timeDifferenceInSeconds / 86400);
//     return days === 1 ? "1 day ago" : `${days} days ago`;
//   } else if (timeDifferenceInSeconds < 604800) {
//     const weeks = Math.floor(timeDifferenceInSeconds / 604800);
//     return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
//   } else if (timeDifferenceInSeconds < 2592000 * 2) {
//     // Less than 2 months
//     const formattedDate = updatedTime.toLocaleString("en-US", {
//       month: "short",
//       day: "numeric",
//     });
//     return rtf.format(-1, "month") + " " + formattedDate;
//   } else {
//     // More than 2 months
//     const formattedDate = updatedTime.toLocaleString("en-US", {
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//     });
//     return formattedDate;
//   }
// };
// export const calculateTimeDifference = (timestamp) => {
//   const californiaTime = new Date(timestamp);
//   // Convert California time to IST
//   const istTime = new Date(californiaTime.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
//   const currentTime = new Date();

//   // Adjust for IST (Indian Standard Time)
//   istTime.setHours(istTime.getHours() + 13);
//   istTime.setMinutes(istTime.getMinutes() + 30);

//   const timeDifferenceInSeconds = Math.floor((currentTime - istTime) / 1000);

//   if (timeDifferenceInSeconds < 60) {
//       return "just now";
//   } else if (timeDifferenceInSeconds < 3600) {
//       const minutes = Math.floor(timeDifferenceInSeconds / 60);
//       return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
//   } else if (timeDifferenceInSeconds < 86400) {
//       const hours = Math.floor(timeDifferenceInSeconds / 3600);
//       return `${hours} hour${hours > 1 ? 's' : ''} ago`;
//   } else if (timeDifferenceInSeconds < 604800) {
//       const days = Math.floor(timeDifferenceInSeconds / 86400);
//       return `${days} day${days > 1 ? 's' : ''} ago`;
//   } else {
//       const formattedDate = istTime.toLocaleDateString('en-IN', { day: 'numeric', month: 'short', year: 'numeric' });
//       return formattedDate;
//   }
// };
export const calculateTimeDifference = (timestamp) => {
  const californiaTime = new Date(timestamp);
  const istTime = new Date(californiaTime.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));

  const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(californiaTime);
  const day = californiaTime.getDate();
  const year = californiaTime.getFullYear();

  const timeFormat = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }).format(californiaTime);

  // Adjusted order to place the year at the end
  return `${monthAbbreviation} ${day}, ${timeFormat} ${year}`;
};

  
export const formatRelativeTime = (date) => {
  // Calculate time difference using calculateTimeDifference function
  return calculateTimeDifference(date);
};

// export const calculateTimeDifferenceChat = (timestamp) => {
//   const currentTime = new Date();
//   const updatedTime = new Date(timestamp);

//   // Adjust for IST (Indian Standard Time)
//   updatedTime.setHours(updatedTime.getHours() + 13);
//   updatedTime.setMinutes(updatedTime.getMinutes() + 30);

//   const timeDifferenceInSeconds = Math.floor(
//     (currentTime - updatedTime) / 1000
//   );

//   // Check if the message was sent today
//   if (
//     currentTime.getDate() === updatedTime.getDate() &&
//     currentTime.getMonth() === updatedTime.getMonth() &&
//     currentTime.getFullYear() === updatedTime.getFullYear()
//   ) {
//     // Format time as "2:30 PM"
//     return updatedTime.toLocaleTimeString([], {
//       hour: "numeric",
//       minute: "2-digit",
//     });
//   } else if (timeDifferenceInSeconds < 86400) {
//     // Less than 24 hours ago, show as "yesterday"
//     return "yesterday";
//   } else {
//     // More than 24 hours ago, show as "date/month/year"
//     const formattedDate = updatedTime.toLocaleString("en-US", {
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//     });
//     return formattedDate;
//   }
// };


// export const formatDate = (dateString) => {
//   try {
//     // Log the input date string
//     console.log("Date string:", dateString);
    
//     // Parse the date string
//     const date = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
    
//     // Log the parsed date
//     console.log("Parsed date:", date);
    
//     // Format the Date object
//     return format(date, 'MM-dd-yyyy \'at\' h:mm a');
//   } catch (error) {
//     console.error('Error formatting date:', error);
//     return dateString; // Return original string if formatting fails
//   }
// };

export const formatDate = (dateString) => {
  try {
    // Log the input date string
    // console.log("Date string:", dateString);
    
    let date;

    // Check if the date string contains time information
    if (dateString.includes(' ')) {
      // Parse date with time
      date = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
    } else {
      // Parse date without time
      date = parse(dateString, 'yyyy-MM-dd', new Date());
    }
    
    // Log the parsed date
    // console.log("Parsed date:", date);
    
    // Format the Date object
    return format(date, 'MM-dd-yyyy \'at\' h:mm a');
  } catch (error) {
    // console.error('Error formatting date:', error);
    return dateString; // Return original string if formatting fails
  }
};

export const formatDateforDue = (dateString) => {
  try {
    // Log the input date string
    // console.log("Date string:", dateString);
    
    let date;

    // Check if the date string contains time information
    if (dateString.includes(' ')) {
      // Parse date with time
      date = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
    } else {
      // Parse date without time
      date = parse(dateString, 'yyyy-MM-dd', new Date());
    }
    
    // Log the parsed date
    // console.log("Parsed date:", date);
    
    // Format the Date object
    return format(date, 'MM-dd-yyyy');
  } catch (error) {
    // console.error('Error formatting date:', error);
    return dateString; // Return original string if formatting fails
  }
};
// export const calculateTimeDifferenceChat1 = (timestamp) => {
//   const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

//   const updatedTime = new Date(timestamp);

//   // Adjust for IST (Indian Standard Time)
//   updatedTime.setHours(updatedTime.getHours() + 13);
//   updatedTime.setMinutes(updatedTime.getMinutes() + 30);

//   // Extract the day, month, and year
//   const day = updatedTime.getDate();
//   const month = months[updatedTime.getMonth()];
//   const year = updatedTime.getFullYear();

//   // Format the date as "7 May 2024"
//   const formattedDate = `${day} ${month} ${year}`;
//   return formattedDate;
// };


