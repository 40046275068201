import React, { useState, useEffect } from "react";
import API_BASE_URL from "../../Config";
import "./CreateGroup.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GroupListStatic from "./GroupListStatic";
import EmployeesStatic from "./EmployeesStatic";
const CreateGroup = () => {
  const [groups, setGroups] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [checkedName, setCheckedName] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [title, setTitle] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchGroups();
    handleDepartments();
    handleEmployee();
  }, []);
  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const storedToken = localStorage.getItem("token");
    const userId = selectedUserIds;
    const groupData = {
      name: title,
      users: userId,
    };

    // API call to post the new group
    fetch(`${API_BASE_URL}/api/v1/groups`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
      body: JSON.stringify(groupData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle successful response
        console.log("New group created:", data);
        setTitle("");
        setCheckedName("");
        fetchGroups();
        handleEmployee();

        // Show toast message upon successful creation
        toast.success("New group created successfully!");
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  const fetchGroups = () => {
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      console.error("No token found in localStorage");
      setLoading(false);
      return;
    }

    fetch(`${API_BASE_URL}/api/v1/groups`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setGroups(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };
  const handleDepartments = () => {
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      console.error("No token found in localStorage");
      return;
    }

    fetch(`${API_BASE_URL}/api/v1/departments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the data received from the API
        setDepartments(data.data);
        console.log(data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
      });
  };
  const handleChangesSelect = (event) => {
    console.log(event.target.value);
  };
  const handleEmployee = () => {
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      console.error("No token found in localStorage");
      setLoading(false);
      return;
    }

    fetch(`${API_BASE_URL}/api/v1/users?is_employee=1`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the data received from the API
        setEmployee(data.data);
        console.log(data.data);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
      });
  };
  const filteredData = employee.filter(
    (item) =>
      item.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.last_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  const handleClick = (groupName, usersList, groupId) => {
    setUpdate(true);
    setSelectedGroup({ groupName, usersList, groupId });
  };
  const handleCheckboxUpdate = (event, id, firstName) => {
    if (event.target.checked) {
      setSelectedEmployees([...selectedEmployees, { id, firstName }]);
    } else {
      const updatedEmployees = selectedEmployees.filter((emp) => emp.id !== id);
      setSelectedEmployees(updatedEmployees);
    }
  };
  const handleCheckboxChange = (event, group, name) => {
    console.log("id,name", group, name);
    const isChecked = event.target.checked;

    if (isChecked) {
      // If checkbox is checked, add userId to the selectedUserIds list
      setSelectedUserIds((prevIds) => [...prevIds, group]);
    } else {
      // If checkbox is unchecked, remove userId from the selectedUserIds list
      setSelectedUserIds((prevIds) => prevIds.filter((id) => id !== group));
    }

    if (event.target.checked) {
      // If checkbox is checked, add the name to the checkedNames list
      setCheckedName([...checkedName, { id: group, name }]);
    } else {
      // If checkbox is unchecked, remove the user object from the checkedNames list
      setCheckedName((prevNames) =>
        prevNames.filter((item) => item.id !== group)
      );
    }

    // Update corresponding checkbox in employees list
    const employeeCheckbox = document.getElementById(
      `flexCheckDefault-${group}`
    );
    if (employeeCheckbox) {
      employeeCheckbox.checked = isChecked;
    }
  };

  const handleUpdatedCreate = (event, id, name) => {
    const isChecked = event.target.checked;

    // Update selectedUserIds
    setSelectedUserIds((prevIds) => {
      if (isChecked) {
        // If checkbox is checked, add userId to the selectedUserIds list
        return [...prevIds, id];
      } else {
        // If checkbox is unchecked, remove userId from the selectedUserIds list
        return prevIds.filter((prevId) => prevId !== id);
      }
    });

    // Update checkedName
    setCheckedName((prevNames) => {
      if (isChecked) {
        // If checkbox is checked, add the name to the checkedNames list
        return [...prevNames, { id, name }];
      } else {
        // If checkbox is unchecked, remove the name from the checkedNames list
        return prevNames.filter((item) => item.id !== id);
      }
    });
  };

  const handleUpdateTitle = (e) => {
    setSelectedGroup({
      ...selectedGroup,
      groupName: e.target.value,
    });
  };
  useEffect(() => {
    // Here, you can initialize selectedEmployees based on some data source if needed.
    // For example, if you have an initial list of selected employees in selectedGroup, you can do:
    if (selectedGroup) {
      setSelectedEmployees(
        selectedGroup.usersList.map((employee) => ({
          id: employee.id,
          firstName: employee.first_name,
        }))
      );
    }
  }, [selectedGroup]);
  const handleUpdate = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (!selectedGroup || !selectedGroup.groupId) {
        throw new Error("Invalid selectedGroup data");
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      };

      // Prepare the updated group object with the new name and users
      const updatedGroup = {
        name: selectedGroup.groupName,
        users: selectedEmployees.map((emp) => emp.id),
      };

      // Make API call to update the group with the specified headers
      const response = await axios.put(
        `${API_BASE_URL}/api/v1/groups/${selectedGroup.groupId}`,
        updatedGroup,
        { headers }
      );

      console.log("Update successful:", response.data);
      toast.success("Group updated successfully");
      setTimeout(() => {
        setUpdate(false);
      }, 3000);

      // Optionally, you may want to handle success or show a notification
    } catch (error) {
      console.error("Error updating group:", error);
      if (error.response) {
        // Server responded with an error
        console.error("Server error data:", error.response.data);
      }
      // Optionally, you can log or display more details about the error here
    }
  };
  const handleDelete = async () => {
    const storedToken = localStorage.getItem("token");

    fetch(`${API_BASE_URL}/api/v1/groups/${selectedGroup.groupId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Group deleted successfully:", data);
        toast.success("Group Deleted successfully");
        setTimeout(() => {
          setUpdate(false);
        }, 3000);
        // Optionally, perform any other actions after successful deletion
        // For example, you might want to update the UI or fetch updated group data
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        // Optionally, handle errors or display error messages
      });
  };

  const handleNewCheckboxChange = (event, id, firstName) => {
    if (event.target.checked) {
      // If the checkbox is checked, add the new employee to selectedEmployees
      setSelectedEmployees([...selectedEmployees, { id, firstName }]);
    } else {
      // If the checkbox is unchecked, remove the new employee from selectedEmployees
      const updatedEmployees = selectedEmployees.filter((emp) => emp.id !== id);
      setSelectedEmployees(updatedEmployees);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <span className="d-flex justify-content-between pt-3">
            <h3 className="green-background">Groups List</h3>
            <div className="search-img d-flex justify-content-center align-items-center ">
              <img
                src="	https://beta.yesdone.com/build/assets/whiteplus-5b88000b.png"
                alt=""
                className=""
                height="20px"
                width="20px"
              />
            </div>
          </span>
          {loading ? ( // Show loading spinner if loading is true
            <>
              <GroupListStatic />
            </>
          ) : (
            <div className="list-group card">
              <span className="border-bottom">
                {groups.map((group, groupIndex) => (
                  <button
                    type="button"
                    className="list-group-item list-group-item-action border-bottom"
                    key={groupIndex}
                    onClick={() =>
                      handleClick(group.name, group.users, group.id)
                    }
                  >
                    <h6>{group.name}</h6>
                    <span className="custom-fontsize">
                      {group.users.map((value, index) => (
                        <span key={index}>
                          {`${value.first_name} ${value.last_name}${
                            index !== group.users.length - 1 ? "," : ""
                          }`}
                        </span>
                      ))}
                    </span>
                  </button>
                ))}
              </span>
            </div>
          )}
        </div>
        {update ? (
          <>
            {selectedGroup && (
              <div className="col-md-6 pt-3 px-2">
                <h4 className="green-background pb-1 text-center">
                  Update New List/Group
                </h4>
                <div className="list-group card pt-2">
                  <div className="border-bottom ps-4 py-3 ">
                    <input
                      type="text"
                      value={selectedGroup.groupName}
                      onChange={(e) => handleUpdateTitle(e)}
                      id="video_url"
                      name="video_url"
                      placeholder="List/GroupName"
                      className="col-10 border-0 "
                    />
                  </div>

                  <div className="border-bottom py-3 ps-4  ">
                    <select
                      onChange={handleChangesSelect}
                      className="selectbox border-0 cursor-pointer"
                    >
                      <option value="">Select Departments</option>
                      {departments.map((item) => (
                        <option key={item.id} value={JSON.stringify(item)}>
                          <span>{item.name}</span>
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="card recentCard recentCard1 mx-auto mt-2">
                    <h4 class="card-header text-center">Selected Users</h4>
                    <div className="column-layout">
                      {selectedEmployees.map((employee, index) => (
                        <ul
                          className="list-group list-group-flush border-bottom"
                          key={index}
                        >
                          <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                            <div className="d-flex align-items-center py-1 col-lg-11">
                              <span className="col-lg-1">
                                <i className="bi bi-person-fill"></i>
                              </span>
                              <span className="col-lg-10 post-font">
                                {employee.firstName}
                              </span>
                            </div>
                            <div className="col-lg-1 form-check m-0 me-5">
                              <input
                                className="form-check-input green-border cursor-pointer"
                                type="checkbox"
                                id={`flexCheckDefault-selected-${index}`}
                                onChange={(event) =>
                                  handleCheckboxUpdate(
                                    event,
                                    employee.id,
                                    employee.firstName
                                  )
                                }
                                checked={true} // All existing employees are initially checked
                              />
                            </div>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>

                  <div className="row px-4 py-3 ">
                    <button
                      type="submit"
                      onClick={handleDelete}
                      className="button-post changes-font d-flex justify-content-center align-items-center col-4"
                    >
                      Delete Group
                    </button>
                    <div className="col-4"></div>
                    <button
                      type="submit"
                      onClick={handleUpdate}
                      className="button-post changes-font d-flex justify-content-center align-items-center col-4"
                    >
                      Save Changes
                    </button>
                  </div>
                  <ToastContainer
                    position="top-center"
                    // style={{
                    //   color: "#55a13c",
                    // }}
                  />
                </div>
                <div className="input-group px-3 mb-1 mt-1 py-3">
                  <input
                    type="text"
                    className="form-control ps-5 rectanglesearch cursor-point"
                    placeholder="Search"
                    name=""
                    id="searchTodo"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoComplete="off"
                  />

                  <img
                    src="https://beta.yesdone.com/build/assets/searchicon-534fbd0c.png"
                    alt=""
                    className="search-icon"
                    height="15px"
                    width="15px"
                  ></img>
                </div>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                  <h2 className="accordion-header">
      <button className="accordion-button shadow-none  accordion-header-bg py-2 collapsed text-center" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
      Employees
      </button>
    </h2>
                    {loading ? ( // Show loading spinner if loading is true
                      <>
                        <EmployeesStatic />
                      </>
                    ) : (
                      <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
<div className="column-layout accordion-body">
                        {filteredData
                          .filter((employee) => employee.status === "active") // Filter based on status
                          .map((employee, index) => (
                            <ul
                              className="list-group list-group-flush border-bottom"
                              key={index}
                            >
                              <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                                <div className="col-lg-1 form-check m-0">
                                  <input
                                    className="form-check-input green-border cursor-pointer"
                                    type="checkbox"
                                    id={`flexCheckDefault-employee-${index}`}
                                    onChange={(event) =>
                                      handleNewCheckboxChange(
                                        event,
                                        employee.id,
                                        employee.first_name
                                      )
                                    }
                                    checked={selectedEmployees.some(
                                      (emp) => emp.id === employee.id
                                    )}
                                  />
                                </div>
                                <div className="d-flex align-items-center col-lg-11">
                                  <span className="col-lg-1">
                                    {/* <i className="bi bi-person-fill"></i> */}
                                  </span>
                                  <span className="col-lg-10 post-font pt-2">
                                    {employee.first_name} {employee.last_name}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          ))}
                      </div>
                        </div>
                      
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="col-md-6 py-3 px-2">
            <h4 className="green-background pb-1 text-center">
              Create New List/Group
            </h4>
            <div className="list-group card pt-2">
              <div className="border-bottom ps-4 py-3">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => handleChangeTitle(e)}
                  id="video_url"
                  name="video_url"
                  placeholder="List/GroupName"
                  className="col-10 border-0  form-control shadow-none"
                />
              </div>

              <div className="border-bottom py-3 ps-4  ">
                <select
                  onChange={handleChangesSelect}
                  className="selectbox border-0 form-select shadow-none cursor-pointer"
                >
                  <option value="">Select Departments</option>
                  {departments.map((item) => (
                    <option key={item.id} value={JSON.stringify(item)}>
                      <span>{item.name}</span>
                    </option>
                  ))}
                </select>
              </div>
              <div className=" pt-2">
                {checkedName.length > 0 && (
                  <>
                    <div class="card recentCard mx-auto mt-2">
                      <h4 class="card-header text-center">Selected Users</h4>
                      <div className="column-layout">
                        <ul className="list-group list-group-flush ">
                          {checkedName.map((item, index) => (
                            <li
                              key={index}
                              className="list-group-item d-flex justify-content-between align-items-center p-0 border-bottom"
                            >
                              <div className="d-flex align-items-center py-1 ps-1 col-lg-11">
                                <span className="col-lg-1">
                                  <i className="bi bi-person-fill"></i>
                                </span>
                                <span className="col-lg-10 post-font">
                                  {item.name}
                                </span>
                              </div>
                              <div className="col-lg-1 form-check m-0 me-5">
                                <input
                                  className="form-check-input green-border cursor-pointer"
                                  type="checkbox"
                                  id={`flexCheckDefault-selected-${index}`}
                                  onChange={(event) =>
                                    handleUpdatedCreate(
                                      event,
                                      item.id,
                                      item.name
                                    )
                                  }
                                  checked={true} // All existing employees are initially checked
                                />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="row px-4 py-3 ">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="button-post changes-font font1 d-flex justify-content-center align-items-center"
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div className="input-group px-3 mb-1 mt-1 py-3">
              <input
                type="text"
                className="form-control ps-5 rectanglesearch cursor-point"
                placeholder="Search"
                name=""
                id="searchTodo"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoComplete="off"
              />

              <img
                src="https://beta.yesdone.com/build/assets/searchicon-534fbd0c.png"
                alt=""
                className="search-icon"
                height="15px"
                width="15px"
              ></img>
            </div>
            <ToastContainer
              position="top-center"
              // style={{
              //   color: "#55a13c",
              // }}
            />
            <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className=" accordion-item ">
              {loading ? ( // Show loading spinner if loading is true
                  <>
                    <EmployeesStatic />
                  </>
                ) : (
                  <>
                          <h2 className="accordion-header">
      <button className="accordion-button shadow-none  accordion-header-bg py-2 collapsed text-center" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
      Employees
      </button>
    </h2>
              <div className="accordion-collapse collapse" id="panelsStayOpen-collapseTwo" > 
              <div className="column-layout accordion-body">
                    {filteredData.map((employee, index) => (
                      <ul
                        className="list-group list-group-flush border-bottom"
                        key={index}
                      >
                        <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                          <div className="d-flex align-items-center col-lg-11">
                            <div className="col-lg-1 form-check m-0">
                              <input
                                className="form-check-input green-border cursor-pointer"
                                type="checkbox"
                                value=""
                                id={`flexCheckDefault-${index}`}
                                onChange={(event) =>
                                  handleCheckboxChange(
                                    event,
                                    employee.id,
                                    employee.first_name
                                  )
                                }
                                checked={selectedUserIds.includes(employee.id)}
                              />
                            </div>
                            <span className="col-lg-1">
                              {/* <i className="bi bi-person-fill"></i> */}
                            </span>
                            <span className="col-lg-10 post-font pt-2">
                              {employee.first_name} {employee.last_name}
                            </span>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
             </div>
                 
</>
                )}
            
            </div>
              </div>
        
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateGroup;
